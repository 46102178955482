import SettingsIcon from "@mui/icons-material/Settings";
import TuneIcon from "@mui/icons-material/Tune";
import {
    AppliedRefinerSummary,
    Button,
    DataGrid,
    DataGridColumnDefinition,
    DataGridColumnPreference,
    DataGridPreference,
    DataGridProps,
    DateRange,
    FilterMenu,
    FilterMenuItem,
    getColumnDefinitionId,
    getColumnPreferenceByColumnId,
    GridCellValue,
    ListRefinerGroup,
    Refiner,
    RefinerGroup,
    RefinerGroupType,
    RefinerOption,
    updateRefinersPreference
} from "@aderant/aderant-react-components";
import { OmitStrict } from "aderant-web-fw-core";
import _ from "lodash";
import { RootState } from "MyTypes";
import { HitResult } from "pages/ResultsPage/HitResult";
import React, { CSSProperties, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { appActions } from "state/actions";
import { refinerActions } from "state/actions/RefinerActions";
import { getGridPreferences, getSelectedSubscription, refinerOptionSelector } from "state/selectors";
import store from "state/store/store";
import { conflictsPalette } from "styles/conflictsPalette";
import { PageRefinerPayload, RefinedResults, ViewByRefiner } from "../../state/reducers/ReducerStateTypes";
import { Messages } from "./Messages";
import ViewByRefinerContainer from "./ViewByRefinerContainer";
/**
 * DataGridWithRefiner props. Has a union of @requires "RefinerPayload" type that is specific to pages in the app requiring this component.
 */
/* eslint-disable @typescript-eslint/ban-types */
export type DataGridWithRefinerProps<T extends object> = {
    /* eslint-enable @typescript-eslint/ban-types */
    refiners: RefinerGroup[];
    currentUserId: string;
    /**
     * Unique identifier for this data grid with refiner.  Used to save/load grid preferences
     */
    gridId: string;
    /**
     * Ref for the grid
     */
    gridRef?: React.MutableRefObject<HTMLDivElement | null>;
    /**
     * If there are columns that must never be visible, then they must be placed at the beginning of the columnDefinitions array, and the count is passed into this prop
     */
    alwaysHiddenColumnCount?: number;
    defaultCellDisplayValue?: (cell: GridCellValue) => JSX.Element;
    persistViewByRefiners?: boolean;
    persistRefiners?: boolean;
} & OmitStrict<DataGridProps<T>, "filters" | "data" | "defaultCellDisplayValue"> &
    PageRefinerPayload;

const gridContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    overflowY: "auto",
    backgroundColor: conflictsPalette.background.white
};

/* eslint-disable @typescript-eslint/ban-types */
export function getShowHideMenuOptions<T extends object>(columnDefinitions: readonly DataGridColumnDefinition<T>[], columnPreferences: DataGridColumnPreference[]): FilterMenuItem<string>[] {
    /* eslint-enable @typescript-eslint/ban-types */
    return columnDefinitions
        .filter((definition: DataGridColumnDefinition<T>) => {
            return !definition.isNeverVisible && !definition.fixed;
        })
        .map((definition: DataGridColumnDefinition<T>) => {
            const [preference, preferenceNotFound] = getColumnPreferenceByColumnId(columnPreferences, getColumnDefinitionId(definition));
            let isVisible = false;
            if (preferenceNotFound || preference.hidden === undefined) {
                isVisible = definition.isVisible === undefined ? true : definition.isVisible;
            } else {
                isVisible = !preference.hidden;
            }
            return {
                displayName: definition.columnName.toString(),
                value: getColumnDefinitionId(definition),
                checked: isVisible
            };
        })
        .sort((a: FilterMenuItem<string>, b: FilterMenuItem<string>) => {
            return a.displayName.localeCompare(b.displayName);
        });
}

export function getColumnPreferencesSortedBySequence(
    columnDefinitions: readonly DataGridColumnDefinition<HitResult>[],
    columnPreferences: DataGridColumnPreference[]
): Map<string, { displayName: string; sequence?: number }> {
    const columnMap = new Map<string, { displayName: string; sequence?: number }>();
    getVisibleColumns(columnDefinitions, columnPreferences).map((definition: DataGridColumnDefinition<HitResult>) => {
        const columnId = getColumnDefinitionId(definition);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [preference, preferenceNotFound] = getColumnPreferenceByColumnId(columnPreferences, getColumnDefinitionId(definition));
        columnMap.set(columnId, { displayName: columnId === "conflictingParty" ? "Conflicting Party" : definition.columnName.toString(), sequence: preference.sequence });
    });

    return new Map([...columnMap.entries()].sort((a, b) => (!a[1].sequence || !b[1].sequence ? 0 : a[1].sequence - b[1].sequence)));
}

export function getVisibleColumns(columnDefinitions: readonly DataGridColumnDefinition<HitResult>[], columnPreferences: DataGridColumnPreference[]): DataGridColumnDefinition<HitResult>[] {
    return columnDefinitions.filter((definition: DataGridColumnDefinition<HitResult>) => {
        const [preference, preferenceNotFound] = getColumnPreferenceByColumnId(columnPreferences, getColumnDefinitionId(definition));
        let isVisible = false;
        if (preferenceNotFound || preference.hidden === undefined) {
            isVisible = definition.isVisible === undefined ? true : definition.isVisible;
        } else {
            isVisible = !preference.hidden;
        }

        return !definition.isNeverVisible && isVisible;
    });
}

export const gridPreferencesEqualityFunction = (left: DataGridPreference, right: DataGridPreference): boolean => {
    return (
        left.id === right.id &&
        left.columnPreferences.length === right.columnPreferences.length &&
        left.columnPreferences.every((pref: DataGridColumnPreference, index: number) => shallowEqual(pref, right.columnPreferences[index])) &&
        ((left.refinerPreferences === undefined && right.refinerPreferences === undefined) ||
            (left.refinerPreferences !== undefined &&
                right.refinerPreferences !== undefined &&
                left.refinerPreferences.length === right.refinerPreferences.length &&
                left.refinerPreferences.every((pref: RefinerGroup, index: number) => _.isEqual(pref, right.refinerPreferences![index])))) &&
        ((left.viewByPreferences === undefined && right.viewByPreferences === undefined) ||
            (left.viewByPreferences !== undefined &&
                right.viewByPreferences !== undefined &&
                left.viewByPreferences.length === right.viewByPreferences.length &&
                left.viewByPreferences.every((pref: ViewByRefiner, index: number) => _.isEqual(pref, right.viewByPreferences![index]))))
    );
};

//Do not destructure the props here as typescript wont be able to figure out the exact type for results in RefinerPayload
/* eslint-disable @typescript-eslint/ban-types */
function DataGridWithRefiner<T extends object>(props: DataGridWithRefinerProps<T>) {
    /* eslint-enable @typescript-eslint/ban-types */
    const {
        refiners: unsortedRefiners,
        persistRefiners,
        persistViewByRefiners,
        options,
        toolbar,
        refinedResultKey,
        columnDefinitions,
        defaultCellDisplayValue,
        gridRef: gridRefProps,
        ...rest
    } = props;
    const refiners = useMemo(() => {
        //Only sort the refiners once it has been passed in
        const refiners = _.cloneDeep(unsortedRefiners);
        const refinerOrder = store.getState().refiners.orderedRefinerPaths;
        refiners.sort((r1, r2) => refinerOrder.indexOf(r1.path) - refinerOrder.indexOf(r2.path));
        return refiners;
    }, [unsortedRefiners]);
    const [isRefinerOpen, setIsRefinerOpen] = useState(true);
    const [disableRefiner, setDisableRefiner] = useState(false);
    const searchIdsCurrentlyProcessing: string[] = useSelector((rootState: RootState) => rootState.search.searchIdsCurrentlyProcessing);
    const dispatch = useDispatch();
    const gridRef = gridRefProps ?? useRef<HTMLDivElement>(null);
    const subscription = useSelector(getSelectedSubscription);

    const refinedData = useSelector((rootState: RootState) => {
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return rootState.refiners.refinedResults[refinedResultKey] as T[];
    }, shallowEqual);
    const gridPreferences = useSelector(getGridPreferences(props.gridId), gridPreferencesEqualityFunction);

    React.useEffect(
        () => () => {
            //Clear refinedData on dismount
            dispatch(refinerActions.clearRefinedResults());
        },
        []
    );

    if (options) {
        //If they haven't passed options then they don't support selection actions anyway so we can ignore it.
        //Now wrap the onSelectedRowsChanged and add additional handling to disable the refiner.
        const onSelectedRowsChanged = options.onSelectedRowsChanged;
        const onSelectedRowsChangedWrapper = (selectedRows: T[]) => {
            if (onSelectedRowsChanged) {
                onSelectedRowsChanged(selectedRows);
            }
            setDisableRefiner(selectedRows.length > 0);
        };
        options.onSelectedRowsChanged = onSelectedRowsChangedWrapper;
    }

    const refinerContainerStyle: CSSProperties = {
        minWidth: isRefinerOpen ? "300px" : "0",
        width: isRefinerOpen ? "300px" : "0",
        transition: "min-width 0.5s, width 0.5s, border 0.5s",
        overflowY: "auto",
        border: isRefinerOpen ? `1px solid ${conflictsPalette.border}` : `0px solid ${conflictsPalette.border}`,
        backgroundColor: conflictsPalette.background.white,
        boxSizing: "border-box",
        visibility: isRefinerOpen ? "visible" : "hidden"
    };

    const dispatchOptionChange = (path: string, isSelected: boolean, refinerOption?: RefinerOption | DateRange | null) => {
        if (refinerOption && !searchIdsCurrentlyProcessing.length && props.results.length) {
            //this should not fire if a search is processing as there can be a timing issue between the two.
            //e.g. updateRefinerSelection dispatches with the original data --> the search finishes processing and changes the underlying data --> the updateRefinerSelection completes and updates the UI with the original data(!).
            dispatch(refinerActions.updateRefinerSelection({ refinerOption: refinerOption, path: path, isSelected: isSelected, pageRefiner: props }));
        }
    };

    const refinerOptionOnChange = (path: string, optionValue: string, isSelected: boolean) => {
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const optionToUpdate: RefinerOption | undefined = refinerOptionSelector([...(refiners as ListRefinerGroup[])] || [], path, optionValue);
        if (optionToUpdate) {
            dispatchOptionChange(path, isSelected, optionToUpdate);
        }
    };

    const toggleRefiner = () => {
        setIsRefinerOpen(!isRefinerOpen);
    };
    const handleDateRange = (path: string, dateRange: DateRange) => {
        if (dateRange.fromDate || dateRange.toDate) {
            dispatchOptionChange(path, true, dateRange);
        }
    };

    const onShowHideSelectionChanged = (changedItem: OmitStrict<FilterMenuItem<string>, "checked"> & { checked: boolean }) => {
        const newGridPreferences = _.cloneDeep(gridPreferences);
        if (!newGridPreferences.columnPreferences) {
            newGridPreferences.columnPreferences = [];
        }
        const [columnPrefToUpdate, wasCreated] = getColumnPreferenceByColumnId(newGridPreferences.columnPreferences, changedItem.value);
        columnPrefToUpdate.hidden = !changedItem.checked;
        if (wasCreated) {
            newGridPreferences.columnPreferences.push(columnPrefToUpdate);
        }
        if (!subscription?.id) {
            console.error("Subscription id not found in app state. Unable to update grid preferences.");
        } else {
            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscription.id }));
        }
    };

    //Refresh refiners if a change has been made to the data
    React.useEffect(() => {
        if (!searchIdsCurrentlyProcessing.length) {
            dispatch(refinerActions.refreshRefinerSelection(props));
        }
    }, [searchIdsCurrentlyProcessing]);

    function updateGridPreference(newGridPreferences: DataGridPreference) {
        if (!subscription?.id) {
            console.error("Subscription id not found in app state. Unable to update grid preferences.");
        } else {
            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscription.id }));
        }
    }

    //Persist the refiners, if they change
    React.useEffect(() => {
        if (persistRefiners) {
            updateRefinersPreference(gridPreferences, updateGridPreference, refiners);
        }
    }, [refiners]);

    return (
        <div style={{ flexDirection: "row", height: "100%", display: "flex" }}>
            <div className="refiner-container" style={refinerContainerStyle} data-testid={"refiner"}>
                {props.refinedResultKey == RefinedResults.SEARCHES_PAGE && (
                    <ViewByRefinerContainer currentUserId={props.currentUserId} disabled={disableRefiner} gridId={props.gridId} persistViewByRefiners={persistViewByRefiners} />
                )}
                <Refiner disabled={disableRefiner} refinerGroups={refiners} onDateRangeChange={handleDateRange} onOptionIsSelectedChanged={refinerOptionOnChange} />
            </div>
            <div className="grid-container" style={gridContainerStyle} ref={gridRef}>
                <DataGrid<T>
                    data={refinedData}
                    options={options}
                    toolbar={
                        <>
                            <Button
                                rounded
                                color="primary"
                                iconButton
                                onClick={toggleRefiner}
                                startIcon={<TuneIcon />}
                                key="0"
                                aria-label={Messages.TOGGLE_REFINER.getMessage()}
                                title={Messages.TOGGLE_REFINER.getMessage()}
                            />
                            <AppliedRefinerSummary
                                key="1"
                                appliedRefiners={refiners}
                                onClearAll={() => {
                                    dispatch(refinerActions.clearSelectedRefiners(props));
                                }}
                                onDelete={(type: RefinerGroupType, path: string, options?: { refinerOption: RefinerOption | DateRange | null }) => {
                                    dispatchOptionChange(path, false, options?.refinerOption);
                                }}
                            />
                            {toolbar}
                            <FilterMenu
                                items={getShowHideMenuOptions(columnDefinitions, gridPreferences?.columnPreferences ?? [])}
                                iconButton
                                aria-label={Messages.COLUMN_PREFERENCES.getMessage()}
                                startIcon={<SettingsIcon />}
                                rounded
                                variant="outlined"
                                container={gridRef.current}
                                onSelectedChanged={onShowHideSelectionChanged}
                            />
                        </>
                    }
                    columnDefinitions={columnDefinitions}
                    gridPreferences={gridPreferences}
                    onGridPreferencesChange={(newGridPreferences: DataGridPreference) => {
                        if (!subscription?.id) {
                            console.error("Subscription id not found in app state. Unable to update grid preferences.");
                        } else {
                            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscription.id }));
                        }
                    }}
                    defaultCellDisplayValue={defaultCellDisplayValue}
                    {...rest}
                />
            </div>
        </div>
    );
}

// This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const ReactMemoWithGenericParams = React.memo as {
    <T>(fn: T): T;
};
export default ReactMemoWithGenericParams(DataGridWithRefiner);
