import { SearchVersionEdited, SearchVersionNew, SearchVersionUnedited } from "../SearchVersion";
import { v4 as uuid } from "uuid";
import { QuickSearch } from "../QuickSearch";

export function getTestSearchVersionUnedited(withPassedIn: Partial<SearchVersionUnedited> = {}): SearchVersionUnedited {
    return {
        ...withPassedIn,
        id: withPassedIn.id || uuid(),
        searchId: withPassedIn.searchId || uuid(),
        name: withPassedIn.name || "",
        number: withPassedIn.number || "1",
        createdByUserId: withPassedIn.createdByUserId || uuid(),
        requestedByUserId: withPassedIn.requestedByUserId || uuid(),
        requestTerms: withPassedIn.requestTerms || [],
        status: withPassedIn.status || "DRAFT",
        version: withPassedIn.version || 0,
        lastModified: withPassedIn.lastModified || new Date(),
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: false,
        editState: "CURRENT",
        _etag: `"${uuid()}"`,
        createdOn: withPassedIn.createdOn || new Date()
    };
}

export function getTestSearchVersionEdited(withPassedIn: Partial<SearchVersionEdited> = {}): SearchVersionEdited {
    return {
        ...withPassedIn,
        id: withPassedIn.id || uuid(),
        searchId: withPassedIn.searchId || uuid(),
        name: withPassedIn.name || "",
        number: withPassedIn.number || "1",
        createdByUserId: withPassedIn.createdByUserId || uuid(),
        requestedByUserId: withPassedIn.requestedByUserId || uuid(),
        requestTerms: withPassedIn.requestTerms || [],
        status: withPassedIn.status || "DRAFT",
        version: withPassedIn.version || 0,
        lastModified: withPassedIn.lastModified || new Date(),
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: false,
        editState: "UNSAVED",
        _etag: `"${uuid()}"`,
        createdOn: new Date()
    };
}

export function getTestSearchVersionNew(withPassedIn: Partial<SearchVersionNew> = {}): SearchVersionNew {
    return {
        ...withPassedIn,
        id: withPassedIn.id || uuid(),
        searchId: withPassedIn.searchId || uuid(),
        name: withPassedIn.name || "",
        number: undefined,
        createdByUserId: withPassedIn.createdByUserId || uuid(),
        requestedByUserId: withPassedIn.requestedByUserId || uuid(),
        requestTerms: withPassedIn.requestTerms || [],
        status: withPassedIn.status || "DRAFT",
        version: withPassedIn.version || 0,
        lastModified: undefined,
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: false,
        editState: "NEW",
        createdOn: new Date()
    };
}

export function getTestQuickSearch(withPassedIn: Partial<QuickSearch> = {}): QuickSearch {
    return {
        ...withPassedIn,
        id: withPassedIn.id || uuid(),
        searchId: withPassedIn.searchId || uuid(),
        name: withPassedIn.name || "",
        number: withPassedIn.number || "1",
        createdByUserId: withPassedIn.createdByUserId || uuid(),
        requestedByUserId: withPassedIn.requestedByUserId || uuid(),
        requestTerms: withPassedIn.requestTerms || [],
        status: withPassedIn.status || "DRAFT",
        version: withPassedIn.version || 0,
        lastModified: withPassedIn.lastModified || new Date(),
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: false,
        editState: "UNSAVED",
        _etag: `"${uuid()}"`,
        createdOn: new Date(),
        isQuickSearch: true
    };
}

export function getSearchSaveResult(input: SearchVersionNew | SearchVersionEdited, fieldsChangedBySave?: { number?: string; _etag?: string; lastModified?: Date }): SearchVersionUnedited {
    return {
        ...input,
        editState: "CURRENT" as const,
        number: fieldsChangedBySave?.number ?? "0",
        _etag: fieldsChangedBySave?._etag ?? "",
        lastModified: fieldsChangedBySave?.lastModified ?? new Date()
    };
}

export function getQuickSearchSaveResult(input: QuickSearch, fieldsChangedBySave?: { number?: string; _etag?: string; lastModified?: Date }): QuickSearch {
    return {
        ...input,
        editState: "CURRENT" as const,
        number: fieldsChangedBySave?.number ?? "0",
        _etag: fieldsChangedBySave?._etag ?? "",
        lastModified: fieldsChangedBySave?.lastModified ?? new Date()
    };
}
