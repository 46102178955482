import { Message } from "../Validation/Message";

export const AuditMessages = {
    // audit grid column headers
    DATE_AND_TIME: new Message("DATE_AND_TIME", "Date & Time"),
    TYPE: new Message("TYPE", "Type"),
    DESCRIPTION: new Message("DESCRIPTION", "Description"),
    USER: new Message("USER", "User"),

    SYSTEM: new Message("SYSTEM", "System")
};
