import { ConflictsAction, FirmSettings } from "aderant-conflicts-models";
import { Button, MessageDialog, Tab, Tabs } from "@aderant/aderant-react-components";
import PageContent from "components/PageContainer/PageContent";
import React, { CSSProperties, useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router";
import { Action, Location } from "history";
import { adminActions, appActions } from "state/actions";
import { getFirmSettingsData, getIsFirmSettingsPageEdited, isFirmSettingsPageEdited } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import { usePermissionsContext } from "../../state/selectors/appSelectors";
import { buildPage } from "./FirmSettingsSections/FirmSettingsSectionBuilders";
import { FirmSettingsPageComponent } from "./FirmSettingsPageComponent";
import { RootState } from "MyTypes";
import { createSelector } from "reselect";
import { Messages } from "../Shared/Messages";
import { navigateWithoutPrompt } from "utilities/navigation";

const pageTabs: CSSProperties = {
    borderBottom: `1px solid ${conflictsPalette.primary.Ahsoka}`
};

const tabAccessibilityProps = (index: number): Record<string, string> => {
    return {
        id: `firm-settings-page-tab-${index}`,
        "aria-controls": `firm-settings-page-tabpanel-${index}`
    };
};

const tabPanelAccessibilityProps = (index: number): Record<string, string> => {
    return {
        role: "tabpanel",
        id: `firm-settings-page-tabpanel-${index}`,
        "aria-labelledby": `firm-settings-page-tab-${index}`
    };
};

export function FirmSettingsPage(): JSX.Element | null {
    const [selectedTab, setSelectedTab] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const permissions = usePermissionsContext();
    const firmSettingsData = useSelector(getFirmSettingsData);

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const pendingNavigationOperation = useRef<(() => void) | undefined>(undefined);

    const [isUpdateChangesOnLeaveDialogOpen, setIsUpdateChangesOnLeaveDialogOpen] = useState(false);

    const visiblePageDefinitionsBasedOnPermissions = FirmSettings.sortedPageDefinitions.filter((definition) => {
        return permissions.currentUserHasPermission(definition.requiredPermission);
    });

    if (visiblePageDefinitionsBasedOnPermissions.length < 1) {
        history.push("/");
        dispatch(appActions.showError("You do not have the appropriate permissions to access firm settings."));
        return null;
    }

    const currentPageDefinition = visiblePageDefinitionsBasedOnPermissions[selectedTab];
    const isOnlyOneTabVisible = visiblePageDefinitionsBasedOnPermissions.length === 1;

    const pageEditState = useSelector((rootState: RootState) => rootState.admin.isEdited);
    const isCurrentPageEditedSelector = (rootState: RootState) => getIsFirmSettingsPageEdited(rootState, currentPageDefinition.name);
    const isCurrentPageEdited = useSelector(isCurrentPageEditedSelector);

    const shouldNavigateAway = useRef<boolean>(false);
    const isAnyPageEdited = useSelector(
        createSelector(
            (rootState: RootState) => rootState.admin.isEdited,
            isCurrentPageEditedSelector,
            (editState, isCurrentPageEdited) => {
                const isAnyPageEdited = Object.values(editState).find((v) => v) ?? false;
                return isAnyPageEdited || isCurrentPageEdited;
            }
        )
    );
    const onNavigateAway: (location: Location, action: Action) => boolean = (location: Location, action: Action) => {
        console.log(`onNavigateAway called, isAnyPageEdited: ${isAnyPageEdited}, shouldNavigateAway: ${shouldNavigateAway.current}`);
        //This blocks the user's requests to navigate away and opens the dialog. The check for
        //"shouldNavigateAway.current === false" ensures that if the user still requests to navigate
        //away via the options presented in the dialog, the user will be navigated to where they initially requested
        //when the navigateWithoutPrompt function sets shouldNavigateAway.current to "true".
        if (isAnyPageEdited && shouldNavigateAway.current === false) {
            pendingNavigationOperation.current = () => navigateWithoutPrompt({ location, action }, history, shouldNavigateAway);
            // This is a fix for the back button popping the history when we don't navigate away
            if (action === "POP") {
                history.goForward();
            }
            setIsUpdateChangesOnLeaveDialogOpen(true);
            return false;
        }
        return true;
    };

    const onBeforeUnload = useCallback(
        (event: Event) => {
            if (isAnyPageEdited) {
                // filthy hack: I think there might be a bug in the tab control that causes it to re-fire the last tab change event when you refresh,
                // by setting this here we make the handleTabChange function do nothing instead.
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                pendingNavigationOperation.current = () => {};
                event.preventDefault(); //this will cause the "You have unsaved changes" dialog to pop up on refresh/close/back (only on back if previous page was not in the app).
                event.returnValue = false; //deprecated in favor of preventDefault, using for old browser compat
            }
        },
        [isAnyPageEdited]
    );

    useEffect(() => {
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    });

    //data stored as a Record<string, any> for each section, keyed by Section name, keyed again by Page name.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    const [settingsData, setSettingsData] = useState<Record<string, Record<string, Record<string, any>>>>(firmSettingsData);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSectionChanged = (pageName: string, sectionName: string, newData: Record<string, any>) => {
        //settingsData contains the data for all pages, therefore we only want to override the data containing the section where data has changed.
        setSettingsData({
            ...settingsData,
            [pageName]: {
                ...settingsData[pageName],
                [sectionName]: newData
            }
        });
        dispatch(adminActions.setIsPageEdited({ pageName: pageName, isPageEdited: true }));
    };

    useEffect(() => {
        setSettingsData(firmSettingsData);
    }, [firmSettingsData]);

    const getData = (pageName: FirmSettings.PageDefinitionName) => {
        if (pageName === FirmSettings.SynonymManagementDefinition.name) {
            dispatch(adminActions.fetchSynonymMap());
            dispatch(adminActions.fetchSynonymsStatus());
        } else {
            dispatch(adminActions.fetchFirmSettingsPageData({ pageName: pageName }));
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleTabChange = (event: any, newValue: any): void => {
        if (pendingNavigationOperation.current) {
            //sometimes tabchange events will fire again after pendingNavigationOperation has already been called
            //this makes sure that doesn't cause double dialogs
            pendingNavigationOperation.current = undefined;
            return;
        }

        if (isCurrentPageEdited && !shouldNavigateAway.current) {
            pendingNavigationOperation.current = () => setSelectedTab(newValue);
            setIsUpdateChangesOnLeaveDialogOpen(true);
        } else {
            setSelectedTab(newValue);
        }
    };
    const isPageBasic = FirmSettings.isPageBasic(currentPageDefinition);
    const visibleBuiltPages = visiblePageDefinitionsBasedOnPermissions.map((pageDefinition) => buildPage(pageDefinition, onSectionChanged, settingsData));

    //initial page data fetch for basic pages
    useEffect(() => {
        const visibleBasicPages = visiblePageDefinitionsBasedOnPermissions.filter(FirmSettings.isPageBasic).map((d) => d.name);
        visibleBasicPages.forEach((name) => getData(name));
    }, []);

    if (permissions.currentUserHasPermission(ConflictsAction.ViewSettingsPage)) {
        return (
            <>
                <div style={{ display: "flex", margin: "0 10%", position: "relative" }}>
                    <h1 style={{ fontWeight: 300, margin: "1rem auto", fontSize: "1.5rem" }}>{FirmSettings.Messages.FIRM_SETTINGS.getMessage()}</h1>
                </div>
                <Prompt when={true} message={onNavigateAway} />
                <PageContent style={{ margin: "0px 10% 30px 10%" }}>
                    <div style={{ height: "100%", backgroundColor: `${conflictsPalette.background.grey}`, display: "flex", flexDirection: "column" }}>
                        <div style={pageTabs}>
                            <Tabs value={selectedTab} onChange={handleTabChange} centered aria-label="firm settings page tabs">
                                {visiblePageDefinitionsBasedOnPermissions.map((pageDefinition) => {
                                    return (
                                        <Tab
                                            key={pageDefinition.name}
                                            required={isFirmSettingsPageEdited(pageEditState, pageDefinition.name)}
                                            label={FirmSettings.getDefinitionDisplayName(pageDefinition)}
                                            {...tabAccessibilityProps(visiblePageDefinitionsBasedOnPermissions.indexOf(pageDefinition))}
                                            disabled={isOnlyOneTabVisible}
                                        ></Tab>
                                    );
                                })}
                            </Tabs>
                        </div>
                        <FirmSettingsPageComponent
                            pageName={currentPageDefinition.name}
                            isPageBasic={isPageBasic}
                            sections={visibleBuiltPages[selectedTab]}
                            data={settingsData[currentPageDefinition.name]}
                            {...tabPanelAccessibilityProps(visiblePageDefinitionsBasedOnPermissions.indexOf(currentPageDefinition))}
                            getData={() => getData(currentPageDefinition.name)}
                        />
                    </div>
                </PageContent>
                <MessageDialog
                    size="sm"
                    open={isUpdateChangesOnLeaveDialogOpen}
                    onClose={() => setIsUpdateChangesOnLeaveDialogOpen(false)}
                    message={Messages.LEAVE_WITHOUT_SAVING.getMessage()}
                    title={Messages.LEAVE_WITHOUT_SAVING_TITLE.getMessage()}
                    footer={
                        <>
                            <Button
                                text={Messages.BUTTON_CANCEL.getMessage()}
                                color="secondary"
                                onClick={() => {
                                    setIsUpdateChangesOnLeaveDialogOpen(false);
                                }}
                            />
                            <Button
                                text={Messages.BUTTON_LEAVE.getMessage()}
                                onClick={() => {
                                    getData(currentPageDefinition.name);
                                    if (pendingNavigationOperation.current) {
                                        pendingNavigationOperation.current();
                                        pendingNavigationOperation.current = undefined;
                                    }
                                    setIsUpdateChangesOnLeaveDialogOpen(false);
                                }}
                            />
                        </>
                    }
                />
            </>
        );
    } else {
        history.push("/");
        dispatch(appActions.showError("You do not have the appropriate permissions to access firm settings."));
        return null;
    }
}
