import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider as ReduxProvider } from "react-redux";
import store from "./state/store/store";
import { CssBaseline, ThemeProvider, StyledEngineProvider } from "@mui/material";
import "./styles.css";
import { AderantTheme } from "@aderant/aderant-react-components";
import { MsalProvider } from "@azure/msal-react";
import { msalApp } from "AuthUtils";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { EnvironmentService } from "aderant-conflicts-common";

serviceWorker.unregister();
const container = document.getElementById("root");

if (container === null) {
    //We cannot throw here as this file can get referenced by jest when running tests so we log instead.
    console.warn("Couldn't render page - no root element found.");
} else {
    const root = createRoot(container!);
    const LDClientId = EnvironmentService.getLaunchDarklyClientId();
    (async () => {
        const LDProvider = await asyncWithLDProvider({
            // the default client id is for the production environment, which should be most restrictive
            clientSideID: LDClientId,
            // default context which gets updated once the user is logged in
            reactOptions: {
                useCamelCaseFlagKeys: true
            },
            context: {
                kind: "organization",
                key: "aderant-conflicts",
                name: "Aderant Conflicts"
            },
            options: {
                bootstrap: "localStorage",
                useReport: true //puts context information in the body of the request
            }
        });
        root.render(
            <MsalProvider instance={msalApp}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={AderantTheme}>
                        <CssBaseline />
                        <Router>
                            <ReduxProvider store={store}>
                                <LDProvider>
                                    <App />
                                </LDProvider>
                            </ReduxProvider>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </MsalProvider>
        );
    })();
}
