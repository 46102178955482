import { ConflictsAction } from "../../Permissions";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

const apiKeyManagementSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "api-key-management",
    displayName: Messages.API_KEY_MANAGEMENT.getMessage(),
    defaultValue: {},
    type: "handwritten"
} as const);

export const ApiKeyManagementDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "api-key-management",
    displayName: Messages.API_KEY_MANAGEMENT.getMessage(),
    sections: [apiKeyManagementSection],
    requiredPermission: ConflictsAction.ViewAndCreateApiKeys,
    dataToLoad: ["API_KEYS"]
} as const);
