import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, DataGridPreference, updateViewByPreference } from "@aderant/aderant-react-components";
import { refinerActions } from "state/actions/RefinerActions";
import { Messages } from "./Messages";
import { ViewByNames, VIEW_BY_FILTERS } from "state/reducers/ReducerStateTypes";
import { getGridPreferences, getSelectedSubscription } from "state/selectors";
import { gridPreferencesEqualityFunction } from "components/DataGridWithRefiner/DataGridWithRefiner";
import { appActions } from "state/actions";

type ViewByCheckboxesProps = { gridId: string; currentUserId: string; disabled: boolean; persistViewByRefiners: boolean | undefined };

const ViewByCheckboxes = (props: ViewByCheckboxesProps) => {
    const { gridId, currentUserId, disabled, persistViewByRefiners } = props;
    const dispatch = useDispatch();
    const subscription = useSelector(getSelectedSubscription);
    const gridPreferences = useSelector(getGridPreferences(gridId), gridPreferencesEqualityFunction);
    const [numberSelected, setNumberSelected] = useState(3);
    const [state, setState] = useState({
        [ViewByNames.CREATED_BY]: true,
        [ViewByNames.REQUESTED_BY]: true,
        [ViewByNames.ASSIGNED_TO]: true
    });

    function updateGridPreference(newGridPreferences: DataGridPreference) {
        if (!subscription?.id) {
            console.error("Subscription id not found in app state. Unable to update grid preferences.");
        } else {
            dispatch(appActions.updateGridPreference({ preference: newGridPreferences, subscriptionId: subscription.id }));
        }
    }

    useEffect(() => {
        //load checkboxes from grid preferences
        if (persistViewByRefiners) {
            const createdByIds = gridPreferences?.viewByPreferences?.find((v) => v.name === ViewByNames.CREATED_BY)?.ids;
            const requestedByIds = gridPreferences?.viewByPreferences?.find((v) => v.name === ViewByNames.REQUESTED_BY)?.ids;
            const assignedToIds = gridPreferences?.viewByPreferences?.find((v) => v.name === ViewByNames.ASSIGNED_TO)?.ids;
            let selectedState = {
                [ViewByNames.CREATED_BY]: createdByIds?.length === 0 ? false : true,
                [ViewByNames.REQUESTED_BY]: requestedByIds?.length === 0 ? false : true,
                [ViewByNames.ASSIGNED_TO]: assignedToIds?.length === 0 ? false : true
            };

            //At least one checkbox should be selected
            //count number of selected checkboxes
            let selectedCount = 0;
            if (selectedState[ViewByNames.CREATED_BY]) selectedCount++;
            if (selectedState[ViewByNames.REQUESTED_BY]) selectedCount++;
            if (selectedState[ViewByNames.ASSIGNED_TO]) selectedCount++;
            // if all are unselected then select all
            if (selectedCount === 0) {
                selectedState = {
                    [ViewByNames.CREATED_BY]: true,
                    [ViewByNames.REQUESTED_BY]: true,
                    [ViewByNames.ASSIGNED_TO]: true
                };
                selectedCount = 3;
            }
            setState(selectedState);
            setNumberSelected(selectedCount);
        }
    }, []);

    useEffect(() => {
        //persist the change in viewByRefiners to grid preferences
        if (persistViewByRefiners) {
            const viewByRefiners = VIEW_BY_FILTERS.map((obj) => {
                return { name: obj.name, path: obj.path, ids: state[obj.name] ? [currentUserId] : [] };
            });
            updateViewByPreference(gridPreferences, updateGridPreference, viewByRefiners);
        }
    }, [state]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newNumber = event.target.checked ? numberSelected + 1 : numberSelected - 1;
        const { id, checked } = event.target;
        const viewByObj = VIEW_BY_FILTERS.find((obj) => obj.name === id);
        if (newNumber > 0 && viewByObj) {
            setNumberSelected(newNumber);
            setState((prevState) => {
                return { ...prevState, [id]: checked };
            });
            dispatch(
                refinerActions.updateViewByRefiners({
                    viewByRefiner: { name: viewByObj.name, path: viewByObj.path, ids: checked ? [currentUserId] : [] }
                })
            );
        }
    };

    return (
        <div style={{ paddingLeft: "13px" }}>
            {VIEW_BY_FILTERS.map((view) => {
                const messageKey = `${view.name}_ME`;

                return <Checkbox label={Messages[messageKey].getMessage()} id={view.name} checked={state[view.name]} compact onChange={handleChange} disabled={disabled} key={view.name} />;
            })}
        </div>
    );
};

export default React.memo(ViewByCheckboxes);
