import React from "react";
import { Button } from "@aderant/aderant-react-components";
import { Messages as SharedMessages } from "../Shared/Messages";
import { conflictsPalette } from "styles/conflictsPalette";

type FirmSettingsPageFooterProps = {
    disabled: boolean;
    onCancel: () => void;
    onSave: () => void;
};

export function FirmSettingsPageFooter(props: FirmSettingsPageFooterProps): JSX.Element {
    return (
        <div className="page-footer" style={{ backgroundColor: conflictsPalette.background.footer, marginTop: "auto" }}>
            <Button size="medium" color="secondary" onClick={props.onCancel} text={SharedMessages.BUTTON_CANCEL.getMessage()} disabled={props.disabled} className="footer-button cancel-button" />
            <Button size="medium" color="primary" onClick={props.onSave} text={SharedMessages.BUTTON_SAVE.getMessage()} disabled={props.disabled} className="footer-button save-button" />
        </div>
    );
}
