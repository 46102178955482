import { ArrowBackIos } from "@mui/icons-material";
import {
    currentUserCanChangeAssignedToDirect,
    currentUserCanChangeHitOwnerDirect,
    currentUserCanChangeHitStatusDirect,
    getStatusesUsersCanManuallyChangeSearchToDirect,
    Hit,
    HitIdentifier,
    HitMassEditFields,
    HitStatus,
    HitStatuses,
    QuickSearch,
    SearchVersion,
    User
} from "aderant-conflicts-models";
import { Button, ComboBox, ComboBoxItem, DisplayChip, Escape, getCellDisplayValue, GlobalHotkey, GridCellValue, GridRowInstance, RefinerGroup } from "@aderant/aderant-react-components";
import DataGridWithRefiner, { getColumnPreferencesSortedBySequence, gridPreferencesEqualityFunction } from "components/DataGridWithRefiner/DataGridWithRefiner";
import HitDetailFlyout, { HitDetailFlyoutProps } from "components/HitDetailFlyout/HitDetailFlyout";
import { ResolutionStatusProgressBar } from "components/ResolutionStatusProgressBar/ResolutionStatusProgressBar";
import { hitStatusList } from "dataProviders/hitStatusList";
import { getSearchProgress } from "Functions/search";
import { RootState } from "MyTypes";
import { FeatureBySearchTypeHelper } from "pages/Shared/FeatureBySearchType";
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchActions } from "state/actions";
import { refinerActions } from "state/actions/RefinerActions";
import { GridIds } from "state/reducers/appReducers";
import { RefinedResults } from "state/reducers/ReducerStateTypes";
import { allRefinersSelector, getCurrentSearchIfNotNew } from "state/selectors";
import { conflictsPalette } from "styles/conflictsPalette";
import PageContent from "../../components/PageContainer/PageContent";
import { getGridPreferences, getHitResultGridDefinition, gridConfigurationEqualityFunction, usePermissionsContext } from "../../state/selectors/appSelectors";
import { HitResult } from "./HitResult";
import { ReportSummary, ReportSummaryProps } from "./ReportSummary";
import ResultColumnDefinitions from "./ResultColumnDefinitions";
import ResultDataMapping from "./ResultDataMapping";
import ResultRefinerMapping from "./ResultRefinerMapping";
import { getAllUsers } from "state/selectors";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import { searchRequestsRedirectConditions } from "SearchRoute";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const emptyHitResultGridDefinition = { configurations: [] };

const messages = {
    required: "Required",
    termAlreadyExists: "The entered search term already exists",
    back: "Back",
    search: "Search",
    resultTitle: "Results",
    hitStatus: "Hit Status",
    assignTo: "Assign to"
};

const gridId = GridIds.resultsPage;

export const defaultCellDisplayValue = (cell: GridCellValue): JSX.Element => {
    if (cell === undefined) {
        return <span>{"N/A"}</span>;
    } else if (cell === "**Redacted**") {
        return (
            <div style={{ color: conflictsPalette.text.grey }}>
                <i>**Redacted**</i>
            </div>
        );
    }
    return getCellDisplayValue(cell);
};

export type UserChangePermissions = {
    hitStatus: boolean;
    hitOwner: boolean;
    noChangesAllowed: boolean;
};

export default function ResultsPage(): JSX.Element {
    const dispatch = useDispatch();
    const searchVersion: SearchVersion | QuickSearch | undefined = useSelector((rootState: RootState) => getCurrentSearchIfNotNew(rootState), shallowEqual);
    const [hitResultIdsLoading, setHitResultIdsLoading] = useState<HitIdentifier[]>([]);
    const [selectedRows, setSelectedRows] = useState<HitResult[]>([]);
    const [hitStatusOptions, setHitStatusOptions] = useState<ComboBoxItem<HitStatus>[]>([]);
    const [focusedRow, setFocusedRow] = useState<GridRowInstance<HitResult>>();
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
    const [isFlyoutRowProcessing, setIsFlyoutRowProcessing] = useState(false);
    const [searchReadOnly, setSearchReadOnly] = useState(false);
    const [displayPreviousHitStatusColumn, setDisplayPreviousHitStatusColumn] = useState(false);
    const [statusUpdateableHitIds, setStatusUpdateableHitIds] = useState<HitIdentifier[]>([]);
    const [reassignableHitIds, setReassignableHitIds] = useState<HitIdentifier[]>([]);
    const [requestTermsDialogOpen, setRequestTermsDialogOpen] = useState(false);
    const requestTermsDialogToggled = useRef(false);

    //FEATURE-FLAG: hitOwner
    const isHitOwnerEnabled = useFeatureFlags().hitOwner;

    const displayHitStatus = FeatureBySearchTypeHelper.showHitStatus(searchVersion);
    const displayHitOwner = isHitOwnerEnabled ? FeatureBySearchTypeHelper.showHitOwner(searchVersion) : false;
    const searchIdsCurrentlyProcessing: string[] = useSelector((rootState: RootState) => rootState.search.searchIdsCurrentlyProcessing);
    const refinedResults: HitResult[] = useSelector((rootState: RootState) => rootState.refiners.refinedResults.resultsPage);
    const refiners: RefinerGroup[] = useSelector((rootState: RootState) => {
        return allRefinersSelector(rootState);
    });
    const allUsers = useSelector(getAllUsers);
    const hitResultGridDefinition = useSelector(getHitResultGridDefinition, gridConfigurationEqualityFunction) ?? emptyHitResultGridDefinition;
    const results: HitResult[] = useMemo(() => ResultDataMapping(searchVersion, hitResultGridDefinition, allUsers ?? []), [hitResultGridDefinition, searchVersion, allUsers]);
    const history = useHistory();
    const columnDefinitions = useMemo(
        () => ResultColumnDefinitions(displayPreviousHitStatusColumn, displayHitStatus, displayHitOwner, hitResultGridDefinition, isHitOwnerEnabled),
        [displayPreviousHitStatusColumn, hitResultGridDefinition, displayHitStatus, displayHitOwner]
    );
    const permissions = usePermissionsContext();
    const searchVersionAndPermissions = !!searchVersion && !searchVersion?.isQuickSearch && !!permissions;
    const isRequestStatusLink = searchVersionAndPermissions && getStatusesUsersCanManuallyChangeSearchToDirect(permissions, searchVersion).length > 0;
    const isAssignedToLink = searchVersionAndPermissions && currentUserCanChangeAssignedToDirect(permissions, searchVersion);

    const userHasPermissionToChangeHitStatus = searchVersionAndPermissions ? currentUserCanChangeHitStatusDirect(permissions, searchVersion) : false;
    const userHasPermissionToChangeHitOwner = useMemo(() => {
        const changeableHit = results.find((hitResult) => searchVersionAndPermissions && currentUserCanChangeHitOwnerDirect(permissions, searchVersion, hitResult.hit));
        return !!changeableHit;
    }, [searchReadOnly]);

    const userChangePermissions: UserChangePermissions = {
        hitStatus: userHasPermissionToChangeHitStatus,
        hitOwner: userHasPermissionToChangeHitOwner,
        noChangesAllowed: !userHasPermissionToChangeHitStatus && !userHasPermissionToChangeHitOwner
    };

    const focusedRowRef = useRef<HTMLDivElement>();
    //If a row is clicked while a hit is processing then it should stay on that row after the processing is done.
    const currentRow = useRef();
    const gridPreferences = useSelector(getGridPreferences(gridId), gridPreferencesEqualityFunction);

    useEffect(() => {
        if (isFlyoutOpen) {
            //If we have a focused row and the flyout is open, then focus it again after the grid has rendered
            focusedRowRef?.current?.blur();
            focusedRowRef?.current?.focus();
        }
    });

    useEffect(() => {
        //Clears the refiners if navigating to this page while a search is processing
        dispatch(refinerActions.setRefinerGroups(ResultRefinerMapping(results, displayPreviousHitStatusColumn, displayHitStatus, displayHitOwner)));
        //This will ensure that we are clearing the audits state when unmounting the page
        return () => {
            dispatch(searchActions.clearAudits());
        };
    }, [displayPreviousHitStatusColumn]);

    useEffect(() => {
        //This will ensure that we are clearing the current search state when unmounting the page
        return () => {
            dispatch(searchActions.clearCurrentSearch());
        };
    }, []);

    useEffect(() => {
        setSearchReadOnly(userChangePermissions.noChangesAllowed);
        dispatch(refinerActions.refreshRefinerSelection({ results: results, refinedResultKey: RefinedResults.RESULTS_PAGE }));
    }, [results, displayPreviousHitStatusColumn]);

    useEffect(() => {
        if (displayHitStatus) {
            setHitStatusOptions(permissions ? hitStatusList(permissions, searchVersion) : []);
        }
        setDisplayPreviousHitStatusColumn(searchVersion ? searchVersion?.version > 1 : false);
    }, [searchVersion]);

    useEffect(() => {
        if (focusedRow?.original?.entityId && !focusedRow.original.isRedacted) {
            dispatch(searchActions.fetchEntityDetails({ entityType: focusedRow?.original.sourceType, id: focusedRow?.original?.entityId }));
        }
    }, [focusedRow?.original?.entityId, focusedRow?.original.sourceType]);

    //Close flyout if refiners have filtered out the selected hit
    useEffect(() => {
        const isCurrentSearchProcessing = searchVersion ? !!searchIdsCurrentlyProcessing.find((searchId) => searchId === searchVersion.searchId) : false;
        if (!isCurrentSearchProcessing && focusedRow) {
            const currentRowStillShowing = refinedResults.find((r) => r.hitId.hitId === currentRow.current);
            if (!currentRowStillShowing) {
                currentRow.current = undefined;
                setIsFlyoutOpen(false);
            } else {
                focusedRowRef?.current?.focus();
            }
        }
        !isCurrentSearchProcessing ? setSearchReadOnly(userChangePermissions.noChangesAllowed) : setSearchReadOnly(true);
    }, [searchIdsCurrentlyProcessing]);

    useEffect(() => {
        if (!searchVersion?.isQuickSearch) {
            const currentReassignableHitIds: HitIdentifier[] = selectedRows.reduce((accumulator: HitIdentifier[], currentRow: HitResult) => {
                //If currentRow is a header row it will be undefined
                if (currentRow && searchVersion && currentUserCanChangeHitOwnerDirect(permissions, searchVersion, currentRow.hit)) {
                    accumulator = [...accumulator, currentRow.hitId];
                }
                return accumulator;
            }, []);
            setReassignableHitIds(currentReassignableHitIds);

            const currentStatusUpdateableHitIds: HitIdentifier[] = selectedRows.reduce((accumulator: HitIdentifier[], currentRow: HitResult) => {
                if (isHitOwnerEnabled) {
                    //If currentRow is a header row it will be undefined
                    if (currentRow && searchVersion && !searchVersion?.isQuickSearch && currentUserCanChangeHitStatusDirect(permissions, searchVersion)) {
                        accumulator = [...accumulator, currentRow.hitId];
                    }
                } else {
                    //The existing hit logic allows any user who can select rows to change the hit.
                    currentRow && (accumulator = [...accumulator, currentRow.hitId]);
                }
                return accumulator;
            }, []);
            setStatusUpdateableHitIds(currentStatusUpdateableHitIds);
        }
    }, [selectedRows]);

    const cancel = () => {
        history.push(searchRequestsRedirectConditions(searchVersion));
    };

    const progress = getSearchProgress(searchVersion?.summary?.hitCountByStatus);

    const rowLoadingReset = (loadingRowIds: HitIdentifier[]) => {
        setHitResultIdsLoading((prev) => prev.filter((h) => !loadingRowIds.includes(h)));
        setSearchReadOnly(userChangePermissions.noChangesAllowed);
    };

    const updateHit = (hitChange: HitMassEditFields, hits: HitResult[], onComplete?: () => void) => {
        if (!searchVersion) {
            window.alert("Search version is undefined.");
            return;
        }
        const change: Partial<Hit> = hitChange;

        const hitIds = hits.map((h) => h.hitId);
        setHitResultIdsLoading((prev) => [...prev, ...hitIds]);
        const hitIdsChanged = hits
            .filter((h) => (hitChange.status && h.hitStatus !== hitChange.status) || (hitChange.hitOwnerId !== undefined && h.hit.hitOwnerId !== hitChange.hitOwnerId))
            .map((h) => h.hitId);

        if (hitIdsChanged.length > 0) {
            setSearchReadOnly(true);
            const runOnComplete = () => {
                if (onComplete) {
                    onComplete();
                }
                rowLoadingReset(hitIds);
            };
            dispatch(searchActions.updateHits({ searchId: searchVersion.searchId, versionId: searchVersion.id, _etag: searchVersion._etag, hitIds: hitIdsChanged, change, onComplete: runOnComplete }));
        } else {
            rowLoadingReset(hitIds);
        }
    };

    const onHitChangeInGrid = useCallback(
        (hitChange: HitMassEditFields) => {
            let hits: HitResult[] = [];
            hitChange.status && (hits = selectedRows.filter((row) => row && statusUpdateableHitIds.includes(row.hitId)));
            (hitChange.hitOwnerId || hitChange.hitOwnerId === null) && (hits = selectedRows.filter((row) => row && reassignableHitIds.includes(row.hitId)));
            updateHit(hitChange, hits);
        },
        [searchVersion?.id, permissions.currentUserId, statusUpdateableHitIds, reassignableHitIds]
    );

    const assignToLabel = reassignableHitIds.length > 0 && selectedRows.length > 1 ? messages.assignTo + " (" + reassignableHitIds.length.toString() + ")" : messages.assignTo;
    const hitStatusLabel =
        statusUpdateableHitIds.length > 0 && selectedRows.length > 1 && isHitOwnerEnabled ? messages.hitStatus + " (" + statusUpdateableHitIds.length.toString() + ")" : messages.hitStatus;

    const selectionActions = useMemo(
        () => [
            ...(hitStatusOptions.length > 1 && statusUpdateableHitIds.length
                ? [
                      <ComboBox
                          key="change-status"
                          text={hitStatusLabel}
                          color="secondary"
                          items={hitStatusOptions}
                          disabled={!userChangePermissions.hitStatus}
                          onItemSelected={(i: ComboBoxItem<HitStatus>) => i.value && onHitChangeInGrid({ status: i.value })}
                      />
                  ]
                : []),
            ...(allUsers && reassignableHitIds.length && isHitOwnerEnabled
                ? [
                      <AssignedToUserPickList
                          unassignedWhenEmpty={false}
                          label={assignToLabel}
                          key="grid-hit-owner-picklist"
                          id={"grid-hit-owner-picklist"}
                          disabled={!userChangePermissions.hitOwner}
                          onUserChanged={(user: User | null | undefined) => {
                              onHitChangeInGrid({ hitOwnerId: user ? user?.id : null });
                          }}
                          labelProps={{ width: "100px", position: "left" }}
                      />
                  ]
                : [])
        ],
        [hitStatusOptions, onHitChangeInGrid, searchReadOnly, reassignableHitIds, statusUpdateableHitIds]
    );

    const getRowProps = useCallback(
        (row: GridRowInstance<HitResult>) => {
            const isCurrentRow = currentRow.current && row?.original && row.original.id === currentRow.current;
            const onFocus = (e) => {
                if (!row.canExpand) {
                    setFocusedRow(row);
                    if (searchVersion && !searchVersion.isQuickSearch) {
                        dispatch(
                            searchActions.focusHit({
                                searchId: searchVersion.searchId,
                                versionId: searchVersion.id,
                                hitIdentifier: {
                                    requestTermId: row.original.hitId.requestTermId,
                                    hitEntityId: row.original.hit.sourceData.id,
                                    hitEntityType: row.original.hit.sourceType
                                }
                            })
                        );
                    }
                    setIsFlyoutOpen(true);
                }
            };
            //Set current row
            if (!isFlyoutRowProcessing && focusedRow) {
                currentRow.current = focusedRow.original.id;
            }
            //Ensure the focus follows the row if it moves e.g. If the grid rows are reordered by a refiner.
            if (isCurrentRow && !isFlyoutRowProcessing) {
                return {
                    onFocus: onFocus,
                    ref: focusedRowRef
                };
            }
            let style: CSSProperties = {};
            if (!row.canExpand && row?.original && row.original.version > 1) {
                if (row.original.hitStatus === HitStatuses.Confirm || (row.original.hitStatus === HitStatuses.Unactioned && !row.original.previousVersionStatus)) {
                    style = {
                        backgroundColor: conflictsPalette.background.highlight
                    };
                }
            }
            return {
                onFocus: onFocus,
                style
            };
        },
        [focusedRow, searchIdsCurrentlyProcessing]
    );

    //This needs to be memoized as it will always return a new object per re-render
    const options = useMemo(() => {
        if ((isHitOwnerEnabled && userChangePermissions.hitOwner) || userChangePermissions.hitStatus) {
            return {
                allowSelection: true,
                selectionActions: selectionActions,
                onSelectedRowsChanged: setSelectedRows
            } as const;
        }
        return {
            allowSelection: false
        } as const;
    }, [selectionActions, searchReadOnly, setSelectedRows]);

    const rowMetadata = {
        getRowProps: getRowProps,
        getIsRowLoading: useCallback((rowData: HitResult) => (rowData.hitId ? hitResultIdsLoading.includes(rowData.hitId) : false), [hitResultIdsLoading]),
        getRowId: useCallback((rowData: HitResult) => rowData.id.toString(), [])
    };

    //Handler for Request Status change. Can't pass the callback to Results page, as that requires setting selectedRows state on the grid.
    //TODO (1980): There should be a spinner as part of an overlay to disable any user action until changes are persisted.
    const onHitChangeInFlyout = (status?: HitStatus, hitOwnerId?: string | null) => {
        if (!focusedRow?.original || (!status && hitOwnerId === undefined)) {
            return;
        }
        setIsFlyoutRowProcessing(true);
        updateHit({ status: status, hitOwnerId: hitOwnerId }, [focusedRow.original], () => {
            setIsFlyoutRowProcessing(false);
        });
    };
    const gridRef = useRef<HTMLDivElement>(null);
    // Need to use the global MouseEvent and TouchEvent, not the React ones so that they match the event type used by Material in onClickAway
    const onFlyoutClickAway = (e: MouseEvent | TouchEvent): void => {
        // This was written prior to adding @typescript-eslint/consistent-type-assertions, please refactor when possible.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const target = e.target as HTMLElement;
        const targetDiv = target.closest("div");
        const targetRole = targetDiv?.attributes?.["role"]?.value;
        const parentRole = targetDiv?.parentElement?.attributes?.["role"]?.value;
        const grandparentRole = targetDiv?.parentElement?.parentElement?.attributes?.["role"]?.value;
        //If the last action that called this function was from the request terms dialog then leave the flyout open.
        if (requestTermsDialogToggled.current) {
            requestTermsDialogToggled.current = false;
        } else if (targetRole === "presentation" || targetRole === "dialog" || parentRole === "dialog" || grandparentRole === "dialog") {
            //If the error dialog is open then leave the flyout open.
        } else if (
            //Close the flyout if we have clicked off the grid, on a column header or on a button/checkbox.
            !gridRef.current?.contains(target) ||
            targetRole === "columnheader" ||
            parentRole === "columnheader" ||
            grandparentRole === "columnheader" ||
            target.attributes?.["role"]?.value === "button" ||
            target.attributes?.["type"]?.value === "checkbox"
        ) {
            setIsFlyoutOpen(false);
        }
    };

    const toggleRequestTermsDialog = () => {
        setRequestTermsDialogOpen(!requestTermsDialogOpen);
        requestTermsDialogToggled.current = true;
    };

    let summaryProps: Partial<ReportSummaryProps>;
    let hitDetailFlyoutProps: Partial<HitDetailFlyoutProps>;
    if (searchVersion?.isQuickSearch) {
        summaryProps = {
            showAssignedTo: false,
            showRequestStatus: false,
            showSearchNumber: false,
            searchVersion: searchVersion,
            isRequestStatusLink: false,
            isAssignedToLink: false
        };
        hitDetailFlyoutProps = {
            searchReadOnly: true,
            searchVersion: searchVersion,
            displayTabs: false
        };
    } else {
        summaryProps = {
            showAssignedTo: true,
            showRequestStatus: true,
            showSearchNumber: true,
            searchVersion: searchVersion,
            isRequestStatusLink: isRequestStatusLink,
            isAssignedToLink: isAssignedToLink
        };
        hitDetailFlyoutProps = {
            searchReadOnly: searchReadOnly,
            onHitChange: onHitChangeInFlyout,
            searchVersion: searchVersion,
            displayTabs: true
        };
    }
    return (
        <>
            <GlobalHotkey hotkey={Escape} onDown={() => setIsFlyoutOpen(false)}>
                <div style={{ display: "flex", margin: "0 32px", justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", alignContent: "center" }}>
                    <div style={{ maxWidth: "10%", minWidth: "8.312rem", margin: "1rem 0" }}>
                        <Button onClick={cancel} startIcon={<ArrowBackIos />} text={messages.back} rounded color="secondary" style={{ height: "fit-content", alignSelf: "center" }} />
                    </div>
                    <ReportSummary
                        currentUserId={permissions.currentUserId}
                        results={refinedResults}
                        refiners={refiners}
                        gridColumns={getColumnPreferencesSortedBySequence(columnDefinitions, gridPreferences.columnPreferences)}
                        {...summaryProps}
                    />
                    {displayHitStatus ? (
                        <ResolutionStatusProgressBar
                            data-testid={"results-progress-bar"} //id added here to encapsulate the entire progress bar INCLUDING the completed text
                            style={{ maxWidth: "10%", minWidth: "8.312rem", margin: "1rem 0", display: searchVersion ? "inline-block" : "none" }}
                            progress={progress.progress}
                            total={progress.total}
                            alignment="right"
                        />
                    ) : (
                        DisplayChip({
                            text: `${results.length} Hits`,
                            chipColor: conflictsPalette.status.greenLight,
                            chipBorderColor: conflictsPalette.status.green,
                            textColor: conflictsPalette.text.black
                        })
                    )}
                </div>

                <PageContent style={{ marginTop: 0 }}>
                    <DataGridWithRefiner<HitResult>
                        refinedResultKey={RefinedResults.RESULTS_PAGE}
                        results={results}
                        columnDefinitions={columnDefinitions}
                        alwaysHiddenColumnCount={1}
                        gridId={gridId}
                        gridRef={gridRef}
                        options={options}
                        refiners={refiners}
                        currentUserId={permissions.currentUserId}
                        defaultCellDisplayValue={defaultCellDisplayValue}
                        rowMetadata={rowMetadata}
                    />
                </PageContent>
                <HitDetailFlyout
                    open={isFlyoutOpen}
                    data={focusedRow?.original}
                    onClose={() => {
                        setIsFlyoutOpen(false);
                    }}
                    toggleRequestTermsDialog={toggleRequestTermsDialog}
                    requestTermsDialogOpen={requestTermsDialogOpen}
                    onClickAway={onFlyoutClickAway}
                    entityId={focusedRow?.original?.entityId}
                    userChangePermissions={{
                        ...userChangePermissions,
                        hitOwner: searchVersionAndPermissions && focusedRow?.original?.hit && currentUserCanChangeHitOwnerDirect(permissions, searchVersion, focusedRow.original.hit)
                    }}
                    {...hitDetailFlyoutProps}
                />
            </GlobalHotkey>
        </>
    );
}
