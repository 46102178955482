import AddIcon from "@mui/icons-material/Add";
import {
    currentUserCanChangeAssignedToDirect,
    currentUserCanDeleteDirect,
    getSearchSummariesIds,
    getStatusesUsersCanManuallyChangeSearchToDirect,
    SearchStatus,
    SearchStatuses,
    SearchSummary,
    SearchVersionEtag,
    SearchVersionIdentifier,
    User
} from "aderant-conflicts-models";
import { Button, DataGridColumnDefinition, RefinerGroup } from "@aderant/aderant-react-components";
import { AssignedToUserPickList } from "components/AssignedToUserPickList/AssignedToUserPickList";
import DataGridWithRefiner, { gridPreferencesEqualityFunction } from "components/DataGridWithRefiner/DataGridWithRefiner";
import PageContent from "components/PageContainer/PageContent";
import { RequestStatusComboBox } from "components/RequestStatusComboBox/RequestStatusComboBox";
import { usePolling } from "hooks/usePolling";
import { RootState } from "MyTypes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { searchActions } from "state/actions";
import { refinerActions } from "state/actions/RefinerActions";
import { GridIds } from "state/reducers/appReducers";
import { allRefinersSelector, getAllSearchingSearchSummaries, getAllUsers, getCreatedSearchesRefinedByViewRefiner } from "state/selectors";
import uuid from "uuid";
import { RefinedResults } from "../../state/reducers/ReducerStateTypes";
import { getGridPreferences, usePermissionsContext } from "../../state/selectors/appSelectors";
import { SearchColumnDefinitions } from "./SearchColumnDefinitions";
import { SearchRefinerMapping } from "./SearchRefinerMapping";
import { createSearchRequestRows, SearchRequestRow } from "./SearchRequestRow";
import { Messages } from "./Messages";
import { useFeatureFlags } from "hooks/useFeatureFlags";

export default function SearchesPage() {
    const allUsers: User[] | undefined = useSelector(getAllUsers);
    const searchSummaries: SearchSummary[] = useSelector(getCreatedSearchesRefinedByViewRefiner, shallowEqual);
    const searchingSearchSummaries = useSelector(getAllSearchingSearchSummaries, shallowEqual);
    const searchSummariesAreLoaded: boolean = useSelector((rootState: RootState) => rootState.search.areSearchSummariesLoaded);
    const searchIdsCurrentlyProcessing: string[] = useSelector((rootState: RootState) => rootState.search.searchIdsCurrentlyProcessing);
    const refiners: RefinerGroup[] = useSelector(allRefinersSelector, shallowEqual);
    //ToDo: selectedSearches is inferred as any[]. Need to investigate how to make it strongly typed.
    const [selectedSearches, setSelectedSearches] = useState<SearchRequestRow[]>([]);
    const [statusUpdateableSearchIds, setStatusUpdateableSearchIds] = useState<(SearchVersionIdentifier & SearchVersionEtag)[]>([]);
    const [deletableSearchIds, setDeletableSearchIds] = useState<(SearchVersionIdentifier & SearchVersionEtag)[]>([]);
    const [reassignableSearchIds, setReassignableSearchIds] = useState<(SearchVersionIdentifier & SearchVersionEtag)[]>([]);
    const gridPreferences = useSelector(getGridPreferences(GridIds.searchesPage), gridPreferencesEqualityFunction);
    const dispatch = useDispatch();
    const history = useHistory();
    const permissions = usePermissionsContext();
    //FEATURE-FLAG: all
    const featureFlags = useFeatureFlags();
    console.info(
        `[FeatureFlags] hitOwner: ${featureFlags.hitOwner}, tagging: ${featureFlags.tagging}, autoAssignTo: ${featureFlags.autoAssignTo}, defaultFuzzySearch: ${featureFlags.defaultFuzzySearch}, stickyRefiners: ${featureFlags.stickyRefiners}`
    );

    const searchRequestRows = useMemo(() => {
        if (searchSummaries.length > 0) {
            return createSearchRequestRows(searchSummaries, allUsers ?? []);
        }
        return [];
    }, [searchSummaries, allUsers?.length]);

    const pollingAction = useCallback((data) => {
        const searchingSearchSummariesIds = getSearchSummariesIds(data);
        dispatch(searchActions.fetchSearchSummariesById(searchingSearchSummariesIds));
    }, []);

    // Polling searching search summaries; fetching every 15 seconds for 15 minutes
    usePolling(searchingSearchSummaries, pollingAction, 15000, 60, searchingSearchSummaries.length > 0);

    //Initialises the refiners on first navigating to this page
    //If stickyRefiners is enabled then the refiners will be set to the last state saved to grid preferences
    //If stickyRefiners is disabled then the refiners will be initialised to the default state
    useEffect(() => {
        if (featureFlags.stickyRefiners) {
            dispatch(refinerActions.setRefinerGroups(SearchRefinerMapping(searchRequestRows, gridPreferences.refinerPreferences)));
        } else {
            dispatch(refinerActions.setRefinerGroups(SearchRefinerMapping(searchRequestRows)));
        }
    }, []);

    //Refreshes the refiners whenever a change is made to the search summaries, allUsers is a dependency to ensure when listUsers loads this is updated
    useEffect(() => {
        dispatch(refinerActions.refreshRefinerSelection({ results: searchRequestRows, refinedResultKey: RefinedResults.SEARCHES_PAGE }));
    }, [searchSummaries, allUsers]);

    useEffect(() => {
        const currentStatusUpdateableSearchIds: (SearchVersionIdentifier & SearchVersionEtag)[] = selectedSearches.reduce(
            (acc: (SearchVersionIdentifier & SearchVersionEtag)[], cur: SearchRequestRow) => {
                const currentVersion: SearchSummary | undefined = searchSummaries.find((s?: SearchSummary) => s?.id === cur.id);
                if (currentVersion && getStatusesUsersCanManuallyChangeSearchToDirect(permissions, currentVersion).length) {
                    acc = [...acc, { searchId: currentVersion.id, versionId: currentVersion.versionId, _etag: currentVersion.searchEtag }];
                }
                return acc;
            },
            []
        );
        setStatusUpdateableSearchIds(currentStatusUpdateableSearchIds);

        const currentDeletableSearchIds: (SearchVersionIdentifier & SearchVersionEtag)[] = selectedSearches.reduce((acc: (SearchVersionIdentifier & SearchVersionEtag)[], cur: SearchRequestRow) => {
            const currentVersion: SearchSummary | undefined = searchSummaries.find((s?: SearchSummary) => s?.id === cur.id);
            if (currentVersion && currentUserCanDeleteDirect(permissions, currentVersion)) {
                acc = [...acc, { searchId: currentVersion.id, versionId: currentVersion.versionId, _etag: currentVersion.searchEtag }];
            }
            return acc;
        }, []);
        setDeletableSearchIds(currentDeletableSearchIds);

        const currentReassignableSearchIds: (SearchVersionIdentifier & SearchVersionEtag)[] = selectedSearches.reduce((acc: (SearchVersionIdentifier & SearchVersionEtag)[], cur: SearchRequestRow) => {
            const currentVersion: SearchSummary | undefined = searchSummaries.find((s?: SearchSummary) => s?.id === cur.id);
            if (currentVersion && currentUserCanChangeAssignedToDirect(permissions, currentVersion)) {
                acc = [...acc, { searchId: currentVersion.id, versionId: currentVersion.versionId, _etag: currentVersion.searchEtag }];
            }
            return acc;
        }, []);
        setReassignableSearchIds(currentReassignableSearchIds);
    }, [selectedSearches]);

    const handleNewSearchRequest = () => {
        dispatch(searchActions.isNewSearch(true));
        history.push(`/search/${uuid()}`);
    };

    const searchesPageToolbar: JSX.Element = (
        <>
            <Button
                text={Messages.NEW_SEARCH_REQUEST_BUTTON.getMessage()}
                endIcon={<AddIcon />}
                onClick={handleNewSearchRequest}
                rounded={true}
                size="medium"
                style={{ flexShrink: 0, marginRight: 15 }}
            />
        </>
    );

    const rowMetadata = {
        getIsRowReadOnly: useCallback((rowData: SearchRequestRow) => rowData.statusEnum === SearchStatuses.Searching, []),
        getIsRowLoading: useCallback((rowData: SearchRequestRow) => (rowData.id ? searchIdsCurrentlyProcessing.includes(rowData.id) : false), [searchIdsCurrentlyProcessing]),
        getRowId: useCallback((rowData: SearchRequestRow) => (rowData.id ? rowData.id.toString() : ""), [])
    };

    const deleteSearches = (searchVersionIdentifiers: SearchVersionIdentifier[], event?: React.MouseEvent) => {
        if (event) {
            event.stopPropagation();
        }
        if (searchVersionIdentifiers) {
            dispatch(searchActions.deleteSearches({ searchVersionIdentifiers: searchVersionIdentifiers }));
        } else {
            console.error("Could not delete searches from grid, search ids are null");
        }
    };

    const updateAssignedToUser = (assignedToUser?: User | null) => {
        dispatch(searchActions.updateSearchesAssignedTo({ searchVersionIdentifiers: reassignableSearchIds, change: { assignedToUserId: assignedToUser ? assignedToUser?.id : null } }));
    };

    const updateRequestStatus = (status: SearchStatus) => {
        dispatch(searchActions.updateSearchesSearchStatus({ searchVersionIdentifiers: statusUpdateableSearchIds, change: { status: status } }));
    };

    const searchesColumns: readonly DataGridColumnDefinition<SearchRequestRow>[] = useMemo(
        () => [
            SearchColumnDefinitions.searchId,
            SearchColumnDefinitions.name,
            SearchColumnDefinitions.number,
            SearchColumnDefinitions.createdOn,
            SearchColumnDefinitions.status,
            SearchColumnDefinitions.assignedTo,
            SearchColumnDefinitions.progress,
            SearchColumnDefinitions.createdBy,
            SearchColumnDefinitions.requestedBy,
            SearchColumnDefinitions.lastModified
        ],
        []
    );

    const deleteLabel = deletableSearchIds.length > 0 && selectedSearches.length > 1 ? "Delete (" + deletableSearchIds.length.toString() + ")" : "Delete";
    const assignToLabel = reassignableSearchIds.length > 0 && selectedSearches.length > 1 ? "Assign to (" + reassignableSearchIds.length.toString() + ")" : "Assign to";
    const requestStatusLabel = statusUpdateableSearchIds.length > 0 && selectedSearches.length > 1 ? "Request Status (" + statusUpdateableSearchIds.length.toString() + ")" : "Request Status";

    return (
        <PageContent>
            <DataGridWithRefiner<SearchRequestRow>
                refinedResultKey={RefinedResults.SEARCHES_PAGE}
                gridId={GridIds.searchesPage}
                columnDefinitions={searchesColumns}
                results={searchRequestRows}
                refiners={refiners}
                persistRefiners={featureFlags.stickyRefiners}
                persistViewByRefiners={featureFlags.stickyRefiners}
                toolbar={searchesPageToolbar}
                options={{
                    allowSelection: true,
                    selectionActions: [
                        deletableSearchIds.length > 0 && <Button text={deleteLabel} key="grid-delete-button" onClick={() => deleteSearches(deletableSearchIds)} />,
                        reassignableSearchIds.length > 0 && (
                            <AssignedToUserPickList
                                unassignedWhenEmpty={false}
                                label={assignToLabel}
                                key="grid-assigned-to-user-picklist"
                                id={"grid-assigned-to-user-picklist"}
                                onUserChanged={(user) => updateAssignedToUser(user)}
                                labelProps={{ width: "100px", position: "left" }}
                            />
                        ),
                        statusUpdateableSearchIds.length > 0 && (
                            <RequestStatusComboBox
                                text={requestStatusLabel}
                                key="grid-request-status-combobox"
                                onItemSelected={updateRequestStatus}
                                searchIds={statusUpdateableSearchIds.map((s: SearchVersionIdentifier) => s.searchId)}
                            />
                        )
                    ],
                    onSelectedRowsChanged: setSelectedSearches
                }}
                style={{
                    rowHeight: 50
                }}
                currentUserId={permissions.currentUserId}
                dataIsLoading={!searchSummariesAreLoaded}
                rowMetadata={rowMetadata}
            />
        </PageContent>
    );
}
