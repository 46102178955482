import { OmitStrict } from "aderant-web-fw-core/dist/TypeUtils/OmitStrict";
import { v4 } from "uuid";
import { SearchStatus, createSearch, RequestTerm, SearchVersion, SearchVersionNew } from "./SearchVersion";

export type QuickSearch = OmitStrict<SearchVersion, "isQuickSearch" | "requestTerms" | "status"> & {
    // we have to expose status and requestTerms here as SearchVersionUnedited has this as a readonly property.
    // which means when changing statuses in processperform we are unable to share code and instead have to mutate the object based on a quicksearch condition.
    status: SearchStatus;
    requestTerms: RequestTerm[];
    isQuickSearch: true;
    isLatestVersion: true;
};

export function createQuickSearch(searchVersion: OmitStrict<QuickSearch, "editState" | "applyFuzzySearch" | "createdOn">): QuickSearch {
    return { ...searchVersion, editState: "UNSAVED", applyFuzzySearch: false, createdOn: new Date(), isQuickSearch: true, isLatestVersion: true };
}

export const createNewQuickSearchFromID = (searchId: string, currentUserId: string) => {
    const newQuickSearch: QuickSearch = createQuickSearch({
        id: v4(),
        searchId: searchId,
        createdByUserId: currentUserId,
        requestedByUserId: currentUserId,
        requestTerms: [],
        number: undefined,
        name: "",
        version: 0,
        status: "DRAFT",
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        isQuickSearch: true,
        lastModified: new Date(),
        _etag: ""
    });
    return newQuickSearch;
};

export const createNewSearchVersionFromQuickSearch = (searchId: string, quickSearch: QuickSearch, applyFuzzySearch: boolean): SearchVersionNew => {
    quickSearch.requestTerms.forEach((requestTerm: RequestTerm) => {
        if (!requestTerm.searchTerms.length) {
            requestTerm.searchTerms = [requestTerm.term];
        }
    });
    const newSearchVersion: SearchVersionNew = createSearch({
        id: v4(),
        searchId: searchId,
        createdByUserId: quickSearch.createdByUserId,
        requestedByUserId: quickSearch.createdByUserId,
        requestTerms: quickSearch.requestTerms,
        number: undefined,
        name: "",
        version: 0,
        status: "DRAFT",
        searchDocumentType: "SearchVersion",
        isLatestVersion: true,
        applyFuzzySearch: applyFuzzySearch
    });
    return newSearchVersion;
};

export function setQuickSearchName(quickSearch: QuickSearch) {
    const editableSearch = { ...quickSearch, editState: "UNSAVED" as const };
    let nameString = editableSearch.requestTerms[0].term;
    for (let i = 1; i <= 2; i++) {
        if (editableSearch.requestTerms[i] && editableSearch.requestTerms[i].term) {
            nameString += `, ${editableSearch.requestTerms[i].term}`;
        }
    }

    editableSearch.name = nameString;

    return editableSearch;
}
