import React, { CSSProperties, Fragment, useEffect, useState } from "react";
import { Checkbox } from "@aderant/aderant-react-components";
import { Messages } from "./Messages";
import _ from "lodash";
import { RowLevelSecurity } from "aderant-conflicts-common";
import { getHitResultGridDefinition, gridConfigurationEqualityFunction } from "state/selectors/appSelectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FirmSettings, GridColumnConfiguration } from "aderant-conflicts-models";
import { adminActions } from "state/actions/AdminActions";
import { FirmSettingsPageFooter } from "pages/FirmSettingsPage/FirmSettingsPageFooter";
import { getIsFirmSettingsPagePersisting, getVisibleWhenSecureColumns } from "state/selectors";
import { RootState } from "MyTypes";
import { DataLoadedState, isAllPageDataLoadedOrNotErrored } from "state/reducers/adminReducers";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const optionListContainerStyles: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: "1 1 auto",
    paddingLeft: "6px",
    paddingRight: "6px",
    overflowY: "auto"
};

const optionHeaderContainerStyles: CSSProperties = {
    paddingLeft: "20px",
    paddingRight: "20px"
};

const optionBrickContainerStyles: CSSProperties = {
    flexGrow: 1
};

const optionContainerStyles: CSSProperties = {
    maxWidth: "200px"
};

export type SecureFieldConfiguration = {
    name: string;
    displayName: string;
    isVisibleWhenSecured: boolean;
    isAlwaysVisible?: boolean;
};

const excludedPropertyNames = ["requestTermId"];

export const InformationBarriersComponent = (): JSX.Element => {
    const [secureFieldConfigurations, setSecureFieldConfigurations] = useState<SecureFieldConfiguration[]>([]);
    const [uneditedSecureFieldConfigurations, setUneditedSecureFieldConfigurations] = useState<SecureFieldConfiguration[]>(_.cloneDeep(secureFieldConfigurations));
    const visibleWhenSecureColumnsState = useSelector(getVisibleWhenSecureColumns, shallowEqual);
    //FEATURE-FLAG: hitOwner
    const isHitOwnerEnabled = useFeatureFlags().hitOwner;

    const hitResultGridDefinition = useSelector(getHitResultGridDefinition, gridConfigurationEqualityFunction);
    const pageName = FirmSettings.InformationBarriersDefinition.name;
    const isPersisting: boolean = useSelector((rootState: RootState) => getIsFirmSettingsPagePersisting(rootState, pageName));
    const dispatch = useDispatch();

    const isDirty = useSelector((rootState: RootState) => rootState.admin.isEdited[pageName]);
    const isDataLoaded: DataLoadedState[] = useSelector((rootState: RootState) => rootState.admin.isLoaded[pageName]);
    function setIsDirty(isDirtyInput: boolean) {
        if (!!isDirty !== isDirtyInput) {
            dispatch(adminActions.setIsPageEdited({ pageName, isPageEdited: isDirtyInput }));
        }
    }

    function getOptionCheckbox(secureFieldConfig: SecureFieldConfiguration) {
        return (
            <div style={optionContainerStyles}>
                <Checkbox
                    id={`toggle-${secureFieldConfig.name}-column-visibility`}
                    label={secureFieldConfig.displayName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        secureFieldConfig.isVisibleWhenSecured = !secureFieldConfig.isVisibleWhenSecured;
                        const newSecureFieldConfigurations = _.cloneDeep(secureFieldConfigurations);
                        setSecureFieldConfigurations(newSecureFieldConfigurations);
                        setIsDirty(true);
                    }}
                    checked={secureFieldConfig.isVisibleWhenSecured}
                    disabled={!!secureFieldConfig.isAlwaysVisible || isPersisting}
                />
            </div>
        );
    }

    //This useEffect probably shouldn't be doing all this stuff. This can be moved out.  Don't be too fussed stuffing new stuff in here
    useEffect(() => {
        if (visibleWhenSecureColumnsState && hitResultGridDefinition) {
            const newSecureFieldConfigurations: SecureFieldConfiguration[] = hitResultGridDefinition.configurations
                //FEATURE-FLAG: hitOwner
                .filter((configuration: GridColumnConfiguration) => isHitOwnerEnabled || configuration.propertyName !== "hitOwner")
                .filter(
                    (configuration: GridColumnConfiguration) =>
                        configuration.columnName &&
                        excludedPropertyNames.every((epn) => configuration.propertyName !== epn) &&
                        configuration.propertyName &&
                        !_.some(RowLevelSecurity.hitNeverRedactColumnNames, (name: string) => name === configuration.propertyName)
                )
                .map((configuration: GridColumnConfiguration) => {
                    const isAlwaysVisible = RowLevelSecurity.hitResultNeverRedactColumnNames.some((name: string) => name === configuration.propertyName);
                    return {
                        displayName: configuration.columnName,
                        name: configuration.propertyName,
                        isVisibleWhenSecured: isAlwaysVisible || visibleWhenSecureColumnsState.some((propertyName: string) => propertyName === configuration.propertyName),
                        isAlwaysVisible: isAlwaysVisible
                    };
                });

            //Previous hit status is a weird little column that gets swapped in, it isn't on the grid def, so we need to add it here as an always active column
            newSecureFieldConfigurations.push({
                name: "previousHitStatus",
                displayName: "Previous Hit Status",
                isVisibleWhenSecured: true,
                isAlwaysVisible: true
            });

            //Don't worry about order, the renderer orders them for us
            setSecureFieldConfigurations(newSecureFieldConfigurations);
            setUneditedSecureFieldConfigurations(_.cloneDeep(newSecureFieldConfigurations));
            setIsDirty(false);
        } else if (!visibleWhenSecureColumnsState) {
            dispatch(adminActions.fetchRlsColumnVisibility());
        }
    }, [visibleWhenSecureColumnsState, hitResultGridDefinition]);

    const mapSecureFieldConfigForSave = (secureFieldConfig: SecureFieldConfiguration): string => {
        return secureFieldConfig.name;
    };

    /**
     * The approach here is to chunk the secureFieldConfigurations into "bricks" of 7 to get it to display like the wireframes.  So the layout is:
     * optionListContainer
     * optionBrickContainer
     * optionContainer
     * These are then laid out by flexbox into something resembling a grid
     */
    const [alwaysVisibleOptions, otherOptions] = _.partition(secureFieldConfigurations, (config: SecureFieldConfiguration) => config.isAlwaysVisible);
    alwaysVisibleOptions.sort((a: SecureFieldConfiguration, b: SecureFieldConfiguration) => a.displayName.localeCompare(b.displayName));
    otherOptions.sort((a: SecureFieldConfiguration, b: SecureFieldConfiguration) => a.displayName.localeCompare(b.displayName));

    const optionBricks = _.chunk(otherOptions, alwaysVisibleOptions.length);
    optionBricks.unshift(alwaysVisibleOptions);
    const optionBrickElements = optionBricks.map((optionBrick: SecureFieldConfiguration[]) => {
        return <div style={optionBrickContainerStyles}>{optionBrick.map((secureFieldConfig: SecureFieldConfiguration) => getOptionCheckbox(secureFieldConfig))}</div>;
    });

    //Only return the view if it all page data loaded successfully
    if (isAllPageDataLoadedOrNotErrored(isDataLoaded)) {
        return (
            <Fragment>
                <div style={optionHeaderContainerStyles}>
                    <p>{Messages.DISPLAY_DATA_SUBHEADING_1.getMessage()}</p>
                    <p>{Messages.DISPLAY_DATA_SUBHEADING_2.getMessage()}</p>
                </div>
                <div style={optionListContainerStyles}>{optionBrickElements}</div>
                <FirmSettingsPageFooter
                    disabled={!isDirty || isPersisting}
                    onCancel={() => {
                        setIsDirty(false);
                        setSecureFieldConfigurations(_.cloneDeep(uneditedSecureFieldConfigurations));
                    }}
                    onSave={() => {
                        const configsToSave = _.compact(
                            secureFieldConfigurations.filter((c: SecureFieldConfiguration) => !c.isAlwaysVisible && c.isVisibleWhenSecured).map(mapSecureFieldConfigForSave)
                        );
                        dispatch(
                            adminActions.saveRlsColumnVisibility({
                                rlsVisibleColumns: configsToSave
                            })
                        );
                    }}
                />
            </Fragment>
        );
    }

    return <></>;
};
