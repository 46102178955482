import { Message } from "aderant-conflicts-models";

export const TypeValidationMessages = {
    VLD_NULLUNDEF_BODY: new Message("VLD_NULLUNDEF_BODY", "Request body is null or undefined."),
    VLD_NULLUNDEF_FIELD: new Message<[fieldName: string]>("VLD_NULLUNDEF_FIELD", "Input {0} is null or undefined."),
    VLD_NULLUNDEF_SOURCE_FIELD: new Message<[source: string, fieldName: string]>("VLD_NULLUNDEF_SOURCE_FIELD", "{0} {1} is null or undefined."),
    //**Use for strings/arrays where empty values are disallowed alongside null/undefined values */
    VLD_NULLUNDEFEMPTY_FIELD: new Message<[fieldName: string]>("VLD_NULLUNDEFEMPTY_FIELD", "Input {0} is null, undefined or empty."),
    VLD_NULLUNDEFEMPTY_SOURCE_FIELD: new Message<[source: string, fieldName: string]>("VLD_NULLUNDEFEMPTY_SOURCE_FIELD", "{0} {1} is null, undefined or empty."),
    VLD_EMPTY_OPTIONAL_FIELD: new Message<[fieldName: string]>("VLD_EMPTY_OPTIONAL_FIELD", "Input {0} must be non empty if provided."),
    VLD_NOTOBJECT_BODY: new Message("VLD_NOTOBJECT_BODY", "Expected request body to be an object, not a raw value."),
    VLD_NOTARRAY_BODY: new Message("VLD_NOTARRAY_BODY", "Expected request body to be an array."),
    VLD_WRONGTYPE_FIELD: new Message<[fieldName: string, expectedType: string]>("VLD_WRONGTYPE_FIELD", "Expected input {0} to be a {1}."),
    VLD_WRONGTYPE_OBJECT: new Message<[fieldName: string, expectedFields: string]>("VLD_WRONGTYPE_OBJECT", "Expected input {0} to have field(s) {1}."),
    VLD_WRONGTYPE_SOURCE_FIELD: new Message<[source: string, fieldName: string, expectedType: string]>("VLD_WRONGTYPE_SOURCE_FIELD", "Expected {0} {1} to be a {2}."),
    VLD_RES_WORD_FIELD: new Message<[fieldName: string, reservedWord: string]>("VLD_RES_WORD_FIELD", "{0} cannot be '{1}'. '{1}' is a reserved word."),
    VLD_STRING_TOO_LONG: new Message<[fieldName: string, maxLength: number], "VLD_STRING_TOO_LONG">("VLD_STRING_TOO_LONG", "Value provided for field {0} was longer than {1} characters."),
    VLD_EMPTY_ARRAY: new Message<[arrayName: string]>("VLD_EMPTY_ARRAY", "{0} array is empty."),
    VLD_EMPTY_ARRAY_SOURCE: new Message<[source: string, arrayName: string]>("VLD_EMPTY_ARRAY_SOURCE", "{0} {1} array is empty."),
    VLD_NOT_ARRAY: new Message<[fieldName: string]>("VLD_NOT_ARRAY", "{0} parameter is not an array."),
    VLD_ENUM_FIELD: new Message<[fieldName: string, validValues: string]>("VLD_ENUM_FIELD", "{0} is invalid. Valid values are {1}."),
    VLD_ENUM_SOURCE_FIELD: new Message<[source: string, fieldName: string, validValues: string]>("VLD_ENUM_SOURCE_FIELD", "{0} {1} is invalid. Valid values are {2}."),
    VLD_ETAG_MISMATCH: new Message<[documentType: string, refreshMethod: string]>(
        "VLD_ETAG_MISMATCH",
        "Updates have been made to the {0} document you are trying to save. Please {1} to fetch the latest updates and try again."
    ),
    VLD_DUP_PROPERTY: new Message<[duplicates: string, propertyName: string]>("VLD_DUP_PROPERTY", "The following values are duplicated: {0}. {1} must be unique."),
    VLD_SYNTAX_JSON: new Message<[fileName: string, rootCause: string]>("VLD_SYNTAX_JSON", "{0} has invalid json syntax: {1}.")
};

export const PermissionValidationMessages = {
    PERMISSION_ACTION_FORBIDDEN: new Message<[user: string, action: string]>("PERMISSION_FORBIDDEN", "{0} is not allowed to {1}."),
    PERMISSION_FEATURE_DISABLED: new Message<[feature: string, action: string]>("PERMISSION_FEATURE_DISABLED", "The {0} feature is disabled. {1} is not allowed.")
};
