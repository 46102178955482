import { EditableOptionsFieldDefinition } from "aderant-conflicts-models";
import React from "react";
import { FieldProps } from "./Types/FieldProps";
import { PickList } from "@aderant/aderant-react-components";

type OptionsFieldProps = {
    field: EditableOptionsFieldDefinition;
} & FieldProps;

export function OptionsField(props: OptionsFieldProps): JSX.Element {
    if (props.field.format !== "dropdown") {
        throw new Error("Only dropdown format is supported for OptionsField");
    }

    const currentLabelValuePair = props.field.isDataValid(props.data)
        ? { label: props.field.getDisplayValue(props.data), value: props.data }
        : { label: props.field.getDisplayValue(props.field.defaultValue), value: props.field.defaultValue };

    //Resolve the allowed values for the different allowed value types to the correct format for the PickList component
    const pickListOptions = props.field.allowedValues.map((value) => (value.label && value.value ? { label: value.label, value: value.value } : { label: value.toString(), value: value }));
    return (
        <PickList
            key={props.field.name}
            id={`${props.field.name}-options-${props.field.format}`}
            label={props.field.label}
            labelProps={props.field.pickListProps?.labelProps}
            style={props.field.pickListProps?.style}
            value={{ label: currentLabelValuePair.label, value: currentLabelValuePair.value }}
            onChange={(e, labelValuePair) => props.onChange(labelValuePair?.value ?? props.field.defaultValue)}
            selectOptions={pickListOptions}
            disabled={props.isPagePersisting}
        />
    );
}
