import { ApiUrls, AuthSettings, SendGridSettings } from "aderant-conflicts-environment";
import { endpointDefinitions } from "aderant-conflicts-models";

export type Endpoint = keyof typeof endpointDefinitions.definitions;

function getDevelopmentUrl(name: Endpoint): string {
    const definition = endpointDefinitions.definitions[name];
    const urlTemplate = endpointDefinitions.templates.developmentUrlFormat;

    return urlTemplate.replace("{devPortNumber}", definition.devPortNumber.toString());
}

function getProductionUrl(name: Endpoint): string {
    const definition = endpointDefinitions.definitions[name];
    const urlTemplate = endpointDefinitions.templates.productionUrlFormat;
    const appPrefix = tryGetEnvVariable("WEBSITE_RESOURCE_GROUP");
    return urlTemplate.replace(/{urlId}/g, definition.urlId.toString()).replace(/{appPrefix}/g, appPrefix);
}

export function getFunctionAppUrl(name: Endpoint): URL {
    return new URL(isDevelopmentEnvironment() ? getDevelopmentUrl(name) : getProductionUrl(name));
}

export function getClientWebAppUrl(): string {
    const webAppUrl = tryGetEnvVariable("CONFLICTS_WEBAPPURL");
    return webAppUrl;
}

export function getAuthSettings(): AuthSettings {
    return JSON.parse(tryGetEnvVariable("AUTH_SETTINGS"));
}

export function getApiUrls(): ApiUrls {
    return JSON.parse(tryGetEnvVariable("API_URLS"));
}

export function getAppInsightsInstKey(): string {
    return tryGetEnvVariable("APPINSIGHTS_INSTRUMENTATIONKEY");
}

//The maximum duration, in milliseconds, allowed for the FCDP to process
//the delta blobs, before the monitoring determines that the ingestion failed
export function getMaxDeltaProccessDuration(): number {
    const defaultDuration = 1000 * 60 * 10; //10 minutes
    const duration = tryGetOptionalEnvVariable("MAX_DELTA_PROCESS_DURATION");
    if (duration) {
        const parsed = Number.parseInt(duration);
        if (Number.isNaN(parsed)) {
            return defaultDuration;
        } else {
            return parsed;
        }
    }
    return defaultDuration;
}

//The maximum duration, in milliseconds, to go back from now, when loading pipelineRuns to monitor.
//If there is no pipeline run found for a pipeline, in this time period, it is considered a failed run
export function getMaxPipelineRunLoadDuration(): number {
    const defaultDuration = 1000 * 60 * 60 * 24 * 2; //2 days
    const duration = tryGetOptionalEnvVariable("MAX_PIPELINE_RUN_LOAD_DURATION");
    if (duration) {
        const parsed = Number.parseInt(duration);
        if (Number.isNaN(parsed)) {
            return defaultDuration;
        } else {
            return parsed;
        }
    }
    return defaultDuration;
}

function getEnvVariableAsNumber(valueName: string, defaultValue: number): number {
    const value = tryGetOptionalEnvVariable(valueName);
    if (value) {
        const parsed = Number.parseInt(value);
        if (Number.isNaN(parsed)) {
            return defaultValue;
        } else {
            return parsed;
        }
    }
    return defaultValue;
}

/**
 * The number of concurrent requests to send to the search engine
 * @returns The number of concurrent requests to send to the search engine
 */
export function getSearchEngineRequestConcurrency(): number {
    return getEnvVariableAsNumber("SEARCH_ENGINE_REQUEST_CONCURRENCY", 8);
}

export function getSendGridSettings(): SendGridSettings | null {
    const sendGridSettings = tryGetOptionalEnvVariable("SENDGRID_SETTINGS");
    if (sendGridSettings) {
        return JSON.parse(sendGridSettings);
    }
    return null;
}

export function isDevelopmentEnvironment(): boolean {
    return process.env.NODE_ENV === "development" || process.env.DEV_ENV === "local";
}

function tryGetEnvVariable(name: string): string {
    const value = process.env[name] ?? process.env[`REACT_APP_${name}`]; //all env variables are prefixed with 'REACT_APP_' in the WebApp, we'll just check both formats globally for simplicity.
    if (!value) {
        throw new Error(`${name}/REACT_APP_${name} environment variable did not exist.`);
    }
    return value;
}

//This is a separate function, name rather than tryGetEnvVariable having a parameter for optional,
//so the return type does not include null or undefined, when the variable isn't optional
function tryGetOptionalEnvVariable(name: string): string | null | undefined {
    const value = process.env[name] ?? process.env[`REACT_APP_${name}`]; //all env variables are prefixed with 'REACT_APP_' in the WebApp, we'll just check both formats globally for simplicity.
    return value;
}

export function getLaunchDarklyClientId(): string {
    // the default client id is for the production environment, which should be most restrictive
    return tryGetOptionalEnvVariable("CONFLICTS_LAUNCH_DARKLY_CLIENT_ID") || "666a79ac5b0dd60fc3dee158";
}
