import { ValuesOf } from "aderant-web-fw-core";
import { EditableBooleanFieldDefinition, EditableOptionsFieldDefinition } from "../../Fields/EditableFields/FieldDefinitions";
import { ConflictsAction } from "../../Permissions";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

const isApproverFieldEnabled = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "isApproverFieldEnabled",
    label: Messages.ALLOW_APPROVERS_TO_BE_DESIGNATED.getMessage()
} as const);

const allowStandardUsersToPerformQuickSearch = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "allowStandardUsersToPerformQuickSearch",
    label: Messages.ALLOW_STANDARD_USERS_TO_USE_QUICK_SEARCH.getMessage()
} as const);

const enableFuzzySearchOnQuickSearches = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "enableFuzzySearchOnQuickSearches",
    label: Messages.ENABLE_FUZZY_ON_QUICK_SEARCHES.getMessage()
} as const);

const includeDataFromPriorSearches = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "includeDataFromPriorSearches",
    label: Messages.INCLUDE_DATA_FROM_PRIOR_SEARCHES.getMessage()
} as const);

export const applyFuzzySearchByDefaultForSearchRequests = satisfies<EditableBooleanFieldDefinition>()({
    type: "boolean",
    defaultValue: false,
    name: "applyFuzzySearchByDefaultForSearchRequests",
    label: Messages.APPLY_FUZZY_SEARCH_BY_DEFAULT_FOR_SEARCH_REQUESTS.getMessage()
} as const);

export const AutoAssignToUserTypes = {
    None: "NONE",
    RequestedBy: "REQUESTEDBY",
    CreatedBy: "CREATEDBY",
    Approver: "APPROVER"
} as const;

export type AutoAssignToUserType = ValuesOf<typeof AutoAssignToUserTypes>;

export function isAutoAssignToUserType(value: string): value is AutoAssignToUserType {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return Object.values(AutoAssignToUserTypes).includes(value as AutoAssignToUserType);
}

export const getAutoAssignToUserTypesDisplayValue = (userType: AutoAssignToUserType): string => {
    switch (userType) {
        case "NONE":
            return Messages.NONE.getMessage();
        case "REQUESTEDBY":
            return Messages.REQUESTED_BY.getMessage();
        case "CREATEDBY":
            return Messages.CREATED_BY.getMessage();
        case "APPROVER":
            return Messages.APPROVER.getMessage();
    }
};

const autoAssignToUserTypeAllowedValues = Object.entries(AutoAssignToUserTypes).map((v) => ({ label: getAutoAssignToUserTypesDisplayValue(v[1]), value: v[1] }));

export const autoAssignToUserType = satisfies<EditableOptionsFieldDefinition>()({
    type: "options",
    defaultValue: AutoAssignToUserTypes.None,
    name: "autoAssignToUserType",
    label: Messages.AUTO_ASSIGN_SEARCH_REQUESTS_TO.getMessage(),
    format: "dropdown",
    allowedValues: autoAssignToUserTypeAllowedValues,
    pickListProps: { labelProps: { width: "300px", position: "left" }, style: { width: "500px" } },
    isDataValid: isAutoAssignToUserType,
    getDisplayValue: getAutoAssignToUserTypesDisplayValue
} as const);

const GeneralSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "general",
    displayName: Messages.GENERAL.getMessage(),
    fields: [isApproverFieldEnabled, autoAssignToUserType],
    type: "basic"
} as const);

const QuickSearchesSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "quicksearch",
    displayName: Messages.QUICK_SEARCHES.getMessage(),
    fields: [enableFuzzySearchOnQuickSearches, allowStandardUsersToPerformQuickSearch],
    type: "basic"
} as const);

const SearchDefaultsSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "searchDefaults",
    displayName: Messages.SEARCH_DEFAULTS.getMessage(),
    fields: [includeDataFromPriorSearches],
    type: "basic"
} as const);

const SearchRequestsSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "searchRequests",
    displayName: Messages.SEARCH_REQUESTS.getMessage(),
    fields: [applyFuzzySearchByDefaultForSearchRequests],
    type: "basic"
} as const);

export const FirmOptionsDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "firm-options",
    displayName: Messages.FIRM_OPTIONS.getMessage(),
    sections: [GeneralSection, SearchDefaultsSection, SearchRequestsSection, QuickSearchesSection],
    requiredPermission: ConflictsAction.ViewAndEditFirmOptions,
    dataToLoad: ["FIRM_SETTINGS_PAGE_DATA"]
} as const);
