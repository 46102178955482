import { NotFound } from "aderant-conflicts-models";
import { MultiResponse } from "../Http/HttpResponse";

export const getFetchFirmSettingsMock = (
    value?: boolean
): (() => Promise<
    MultiResponse.Response<
        {
            value: string | number | boolean;
        },
        200,
        NotFound
    >
>) => {
    return jest.fn(() =>
        Promise.resolve({
            status: 207,
            body: [
                {
                    id: "firm-options/searchRequests/applyFuzzySearchByDefaultForSearchRequests",
                    status: 200,
                    body: {
                        value: value ?? false
                    }
                }
            ]
        })
    );
};
