import { BlobStore } from "@aderant/azure-storage";
import { NotFound, UnexpectedError } from "aderant-conflicts-models";

export function getMockBlobStore(mockedResponse?: {
    getBlobContent?: string | UnexpectedError | (() => NotFound);
    uploadBlob?: { etag: "00000000-0000-0000-0000-000000000000" } | UnexpectedError;
}): Pick<BlobStore, "getBlobContent" | "uploadBlob"> {
    return {
        getBlobContent: (path) => {
            return { content: mockedResponse?.getBlobContent };
        },
        uploadBlob: () => Promise.resolve(mockedResponse?.uploadBlob)
    };
}
