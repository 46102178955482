import { useFlags } from "launchdarkly-react-client-sdk";
import { ConflictsFlagSet } from "aderant-conflicts-models";

export function useFeatureFlags(): ConflictsFlagSet {
    // Justification: This is the wrapper class implementation for the useFlags hook
    // eslint-disable-next-line no-restricted-syntax
    const flags = useFlags<ConflictsFlagSet>();
    return {
        hitOwner: flags.hitOwner ?? false,
        tagging: flags.tagging ?? false,
        autoAssignTo: flags.autoAssignTo ?? false,
        defaultFuzzySearch: flags.defaultFuzzySearch ?? false,
        stickyRefiners: flags.stickyRefiners ?? false
    };
}
