import { DurableHitIdentifier, HitComment, SearchVersionIdentifier, User } from "aderant-conflicts-models";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MessageThread, SendBox, DEFAULT_COMPONENT_ICONS, ChatMessage, FluentThemeProvider, MessageProps, MessageRenderer } from "@azure/communication-react";
import { useDispatch, useSelector } from "react-redux";
import { searchActions } from "state/actions";
import { getAllUsers, getCurrentHitComments, getUserName, getCommentsBeingUpdated, isCommentsBeingCreated, isCurrentHitCommentsLoaded, isCommentsLoadingFailed } from "state/selectors";
import { RootState } from "MyTypes";
import { initializeIcons, registerIcons, Persona, PersonaSize } from "@fluentui/react";
import { aderantColors, Chat, SendBox as WCSendBox, DataProviderPopupListProps } from "@aderant/aderant-react-components";
import { UserDataProvider } from "dataProviders/userDataProvider";
import { DataProviderItem } from "@aderant/aderant-react-components";
import { useFeatureFlags } from "hooks/useFeatureFlags";

function mapConflictsMessageToChatMessage(message: HitComment, allUsers: User[] | undefined, currentUser: User): ChatMessage {
    return {
        senderDisplayName: getUserName(message.createdByUserId, allUsers ?? []),
        senderId: message.createdByUserId,
        editedOn: message.createdOn.valueOf() === message.lastModified.valueOf() ? undefined : message.lastModified,
        content: message.text,
        createdOn: message.createdOn,
        contentType: "text",
        messageType: "chat",
        messageId: message.id,
        mine: message.createdByUserId === currentUser.id,
        status: "delivered" //This can be set to delivered if the message has been saved to cosmosdb successfully if we want to utilise the retry behaviour of the chat component.
    };
}

type CommentsContainerProps = {
    currentUser: User;
    durableHitIdentifier: DurableHitIdentifier;
    searchVersionIdentifier: SearchVersionIdentifier;
};

export function CommentsContainer(props: CommentsContainerProps) {
    const { currentUser, durableHitIdentifier, searchVersionIdentifier } = props;
    const allUsers = useSelector(getAllUsers);
    const isCommentsLoadedForCurrentHit = useSelector((state: RootState) => isCurrentHitCommentsLoaded(state, durableHitIdentifier));

    const isTaggingFeatureEnabled = useFeatureFlags().tagging;
    //Tagging feature variables
    //The selectedUsers is of type DataProviderItem[] - to convert to User[] call selectedUsers.map((user) => userDataProvider.getItemById(user.id)):
    const [selectedUsers, setSelectedUsers] = useState<DataProviderItem[]>([]);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const userDataProvider = new UserDataProvider(allUsers ?? []);
    const popupProps: Omit<DataProviderPopupListProps<User>, "inputText" | "setInputText" | "inputElementRef"> = {
        dataProvider: userDataProvider,
        popUpChars: "@",
        id: "userPopup",
        selectedItems: selectedUsers,
        setSelectedItems: setSelectedUsers,
        containerRef: containerRef,
        preventOverflow: true,
        initialPosition: "auto-start",
        sx: { popper: { width: 350, zIndex: 10000 } },
        offset: [0, 6]
    };
    //End of tagging feature variables

    const dispatch = useDispatch();
    useEffect(() => {
        if (durableHitIdentifier && !isCommentsLoadedForCurrentHit) {
            dispatch(searchActions.fetchHitComments({ searchId: searchVersionIdentifier.searchId ?? "", hitIdentifier: durableHitIdentifier }));
        }
    }, [durableHitIdentifier?.requestTermId, durableHitIdentifier?.hitEntityType, durableHitIdentifier?.hitEntityId, searchVersionIdentifier.searchId]);

    const hitComments = useSelector((state: RootState) => getCurrentHitComments(state, durableHitIdentifier));
    const isCreatingComments = useSelector((state: RootState) => isCommentsBeingCreated(state, durableHitIdentifier));
    const commentsBeingUpdated = useSelector((state: RootState) => getCommentsBeingUpdated(state, durableHitIdentifier));
    const commentsLoadingFailed = useSelector((state: RootState) => isCommentsLoadingFailed(state, durableHitIdentifier));

    if (!hitComments) {
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>{!commentsLoadingFailed && <CircularProgress variant="indeterminate" color="primary" size="3em" />}</div>
        );
    }

    initializeIcons();
    registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

    function updateMessageToRender(messageProps: MessageProps, defaultMessageRenderer: MessageRenderer): JSX.Element {
        const MessageElement = () => {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const chatMessage = messageProps.message as ChatMessage;
            return (
                <div>
                    {commentsBeingUpdated.includes(chatMessage.messageId) ? (
                        <div className="loader-wrapper">
                            <div className="loading typing_loader" />
                        </div>
                    ) : chatMessage.mine ? (
                        //Current User's icon
                        <Persona
                            text={chatMessage.senderDisplayName}
                            size={PersonaSize.size32}
                            initialsColor={aderantColors.Ahsoka}
                            initialsTextColor={aderantColors.Stormtrooper}
                            onRenderPrimaryText={() => <></>}
                        />
                    ) : (
                        //Other User's icon
                        <Persona
                            text={chatMessage.senderDisplayName}
                            size={PersonaSize.size32}
                            initialsColor={aderantColors.MillenniumFalcon}
                            initialsTextColor={aderantColors.DarthVaderAlt}
                            onRenderPrimaryText={() => <></>}
                        />
                    )}

                    {defaultMessageRenderer({ ...messageProps })}
                </div>
            );
        };
        return MessageElement();
    }

    return (
        <div className="Chat" ref={containerRef}>
            {isTaggingFeatureEnabled ? (
                <>
                    <WCSendBox
                        mode="add"
                        strings={{ placeholderText: "Leave a comment" }}
                        popupProps={popupProps}
                        onSendMessage={async (content: string) => {
                            dispatch(
                                searchActions.createHitComment({
                                    hitComment: {
                                        durableHitIdentifier: durableHitIdentifier,
                                        searchId: searchVersionIdentifier.searchId,
                                        versionId: searchVersionIdentifier.versionId,
                                        text: content.trim()
                                    }
                                })
                            );
                        }}
                    />

                    <div className="loader-wrapper">{isCreatingComments && <div className="loading typing_loader" />}</div>
                    {hitComments.length > 0 ? (
                        <div id="message-box">
                            <Chat
                                id="Comment thread"
                                userId={currentUser.id}
                                messages={hitComments?.map((message) => mapConflictsMessageToChatMessage(message, allUsers, currentUser)) ?? []}
                                onDeleteMessage={async (messageId: string) => {
                                    dispatch(
                                        searchActions.deleteHitComment({
                                            hitCommentCosmosId: { hitCommentId: messageId, searchId: searchVersionIdentifier.searchId ?? "" },
                                            durableHitIdentifier: durableHitIdentifier
                                        })
                                    );
                                }}
                                onUpdateMessage={(messageId: string, content: string, selectedItems?: DataProviderItem[]) => {
                                    dispatch(
                                        searchActions.editHitComment({
                                            editHitCommentInput: {
                                                hitCommentId: messageId,
                                                searchId: searchVersionIdentifier.searchId ?? "",
                                                newHitCommentText: content
                                            },
                                            durableHitIdentifier: durableHitIdentifier
                                        })
                                    );
                                }}
                                messagesBeingUpdated={commentsBeingUpdated}
                                showMessageDate={true}
                                showMessageTime={true}
                                sendBoxPopupProps={popupProps}
                                styles={{
                                    container: { display: "flex", flexDirection: "column-reverse", backgroundColor: "white", justifyContent: "flex-end", overflow: "none", paddingTop: "1rem" },
                                    /** styles for chat items. */
                                    message: {
                                        container: { maxWidth: "100%", backgroundColor: "white" }
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div style={{ color: aderantColors.DeathStar, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <p style={{ fontSize: "1.125rem", fontWeight: 600, margin: "1.5rem 0 0 0" }}>Add a comment</p>
                            <p style={{ fontSize: "0.9375rem", fontWeight: 400, margin: 0 }}>There are no comments for this hit yet</p>
                        </div>
                    )}
                </>
            ) : (
                <FluentThemeProvider>
                    <SendBox
                        strings={{ placeholderText: "Leave a comment" }}
                        onSendMessage={async (content: string) => {
                            dispatch(
                                searchActions.createHitComment({
                                    hitComment: {
                                        durableHitIdentifier: durableHitIdentifier,
                                        searchId: searchVersionIdentifier.searchId,
                                        versionId: searchVersionIdentifier.versionId,
                                        text: content.trim()
                                    }
                                })
                            );
                        }}
                    />

                    <div className="loader-wrapper">{isCreatingComments && <div className="loading typing_loader" />}</div>
                    {hitComments.length > 0 ? (
                        <div id="message-box">
                            {
                                <MessageThread
                                    userId={currentUser.id}
                                    messages={hitComments?.map((message) => mapConflictsMessageToChatMessage(message, allUsers, currentUser)) ?? []}
                                    onDeleteMessage={async (messageId: string) => {
                                        dispatch(
                                            searchActions.deleteHitComment({
                                                hitCommentCosmosId: { hitCommentId: messageId, searchId: searchVersionIdentifier.searchId ?? "" },
                                                durableHitIdentifier: durableHitIdentifier
                                            })
                                        );
                                    }}
                                    onUpdateMessage={async (messageId: string, content: string) => {
                                        dispatch(
                                            searchActions.editHitComment({
                                                editHitCommentInput: {
                                                    hitCommentId: messageId,
                                                    searchId: searchVersionIdentifier.searchId ?? "",
                                                    newHitCommentText: content
                                                },
                                                durableHitIdentifier: durableHitIdentifier
                                            })
                                        );
                                    }}
                                    showMessageDate={true}
                                    onRenderMessage={(props: MessageProps, defaultOnRender) => {
                                        if (!defaultOnRender) {
                                            throw new Error("defaultOnRender is undefined");
                                        }
                                        return updateMessageToRender(props, defaultOnRender);
                                    }}
                                    styles={{
                                        root: { overflow: undefined, height: "800px" },
                                        chatContainer: { display: "flex", flexDirection: "column-reverse", justifyContent: "flex-end", overflow: "none", paddingTop: "1rem" },
                                        /** styles for my chat items.  */
                                        myChatItemMessageContainer: { margin: "2px", display: "flex", flexDirection: "row", padding: "2px", background: "white" },
                                        /** styles for chat items.  */
                                        chatItemMessageContainer: { display: "flex", flexDirection: "row", margin: "2px", padding: "2px", background: "white" },
                                        /** Styles for my chat message container. */
                                        myChatMessageContainer: { display: "block", maxWidth: "100%", margin: "2px", background: "white", backgroundColor: "white" },
                                        // /** Styles for my chat message container in case of failure. */
                                        // failedMyChatMessageContainer: { background: "red" },
                                        // /** Styles for chat message container. */
                                        chatMessageContainer: { display: "block", maxWidth: "100%", margin: "2px", background: "white", backgroundColor: "white" }
                                        /** Styles for system message container. */
                                    }}
                                />
                            }
                        </div>
                    ) : (
                        <div style={{ color: aderantColors.DeathStar, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <p style={{ fontSize: "1.125rem", fontWeight: 600, margin: "1.5rem 0 0 0" }}>Add a comment</p>
                            <p style={{ fontSize: "0.9375rem", fontWeight: 400, margin: 0 }}>There are no comments for this hit yet</p>
                        </div>
                    )}
                </FluentThemeProvider>
            )}
        </div>
    );
}
