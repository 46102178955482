import { createRefinerOptions, getListRefinerGroups, RefinerGroup, RefinerGroupType, setupDefaultRefinerGroup } from "@aderant/aderant-react-components";
import { SearchRequestRow } from "../SearchesPage/SearchRequestRow";

const searchRefinerGroupPaths = ["createdOn", "lastModified", "status", "isErrored"];
export const SearchRefinerMapping = (results: SearchRequestRow[], refinerGroups?: RefinerGroup[]): RefinerGroup[] => {
    //Filter out refiner groups that don't exist in current grid - these may have been saved to grid preferences but have since been removed from the application
    const validRefinerGroups = refinerGroups?.filter((refinerGroup) => searchRefinerGroupPaths.some((n) => n === refinerGroup.path)) ?? [];
    const newRefinerGroups: RefinerGroup[] =
        validRefinerGroups.length > 0
            ? validRefinerGroups
            : [
                  setupDefaultRefinerGroup({ name: "Created On", path: "createdOn", refinerGroupType: RefinerGroupType.DateRange }),
                  setupDefaultRefinerGroup({ name: "Last Modified", path: "lastModified", refinerGroupType: RefinerGroupType.DateRange }),
                  setupDefaultRefinerGroup({ name: "Request Status", path: "status", refinerGroupType: RefinerGroupType.ListRefiner }),
                  setupDefaultRefinerGroup({ name: "Error", path: "isErrored", refinerGroupType: RefinerGroupType.ListRefiner })
              ];
    createRefinerOptions(getListRefinerGroups(newRefinerGroups), results);
    return newRefinerGroups;
};
