import { BasicCurrentUserContext, CurrentUserContext, FirmSettings, NotFound } from "aderant-conflicts-models";
import { MultiResponse } from "../Http/HttpResponse";
import { FunctionAppContext, KeyAuthFunctionAppContext, PublicKeyAuthFunctionAppContext } from "../ConflictsContext";
import { getSettingsByFieldPaths } from "./FirmSettingsConnector";

export interface FetchFirmSettings {
    (paths: FirmSettings.FieldPath[]): Promise<
        MultiResponse.Response<
            {
                value: string | number | boolean;
            },
            200,
            NotFound
        >
    >;
}

export const fetchFirmSettingsByFieldPaths = (context: PublicKeyAuthFunctionAppContext | FunctionAppContext | KeyAuthFunctionAppContext): FetchFirmSettings => {
    return async (paths: FirmSettings.FieldPath[]) => getSettingsByFieldPaths(context, paths);
};

export async function fetchFuzzySearchDefaultForSearchRequests(context: BasicCurrentUserContext | CurrentUserContext, fetchFirmSettings: FetchFirmSettings) {
    context.logger.info("Fetching fuzzy search default for search requests firm option");
    const fuzzySearchDefaultForSearchRequestsPath = "firm-options/searchRequests/applyFuzzySearchByDefaultForSearchRequests";
    const fetchFirmSettingsResponse = await fetchFirmSettings([fuzzySearchDefaultForSearchRequestsPath]);
    const fuzzySearchDefaultForSearchRequests = fetchFirmSettingsResponse.body.find((r) => r.id === fuzzySearchDefaultForSearchRequestsPath);
    let fuzzyValue: boolean;
    if (!fuzzySearchDefaultForSearchRequests || fuzzySearchDefaultForSearchRequests.status === 404) {
        context.logger.warn("Firm setting for fuzzy search default for search requests not found, defaulting to false");
        fuzzyValue = FirmSettings.applyFuzzySearchByDefaultForSearchRequests.defaultValue;
    } else {
        if (typeof fuzzySearchDefaultForSearchRequests.body.value === "boolean") {
            fuzzyValue = fuzzySearchDefaultForSearchRequests.body.value;
        } else {
            context.logger.warn("Firm setting for fuzzy search default for search requests is not a boolean, defaulting to false");
            fuzzyValue = FirmSettings.applyFuzzySearchByDefaultForSearchRequests.defaultValue;
        }
    }
    return fuzzyValue;
}
