/**
 * Function that checks if a string is in the format of a date string and converts it to a Date object, if it is.
 * This function is used as a reviver function in JSON.parse to convert date strings to Date objects.
 * @param key The key of the object being parsed.
 * @param value The value of the object being parsed.
 */
export function dateReviver(key: string, value: any) {
    // Check if the value is a date string
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (typeof value === "string" && dateRegex.test(value)) {
        return new Date(value);
    }
    return value;
}
