import { ConflictsAction } from "../../Permissions";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

const expertIntegrationSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "expert-integration",
    displayName: Messages.EXPERT_INTEGRATION.getMessage(),
    defaultValue: {},
    type: "handwritten"
} as const);

export const IntegrationDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "integration-management",
    displayName: Messages.INTEGRATIONS.getMessage(),
    sections: [expertIntegrationSection],
    requiredPermission: ConflictsAction.ViewSettingsPage,
    dataToLoad: ["INGESTION_STATUS"]
} as const);
