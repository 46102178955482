import { LaunchDarklyClient } from "aderant-conflicts-models";

const partial: LaunchDarklyClient = {
    variation: (_key, _context, defaultValue) => Promise.resolve(defaultValue),
    boolVariation: (_key, _context, defaultValue) => Promise.resolve(defaultValue),
    stringVariation: (_key, _context, defaultValue) => Promise.resolve(defaultValue),
    numberVariation: (_key, _context, defaultValue) => Promise.resolve(defaultValue),
    jsonVariation: (_key, _context, defaultValue) => Promise.resolve(defaultValue),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    waitForInitialization: () => undefined as unknown as Promise<LaunchDarklyClient>,
    initialized: () => true
};
partial.waitForInitialization = () => Promise.resolve(partial);
export const fallbackLDClient = partial;
