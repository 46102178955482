import { ConflictsAction } from "../../Permissions/UserPermission";
import { satisfies } from "../../UtilityTypes";
import { Messages } from "../Messages";
import { FirmSettingsPageDefinition, FirmSettingsSectionDefinition } from "./InternalTypes/FirmSettingsPageDefinition";

const synonymManagementSection = satisfies<FirmSettingsSectionDefinition>()({
    name: "synonym-management",
    displayName: Messages.SYNONYM_MANAGEMENT.getMessage(),
    defaultValue: {},
    type: "handwritten"
} as const);

export const SynonymManagementDefinition = satisfies<FirmSettingsPageDefinition>()({
    name: "synonym-management",
    displayName: Messages.SYNONYM_MANAGEMENT.getMessage(),
    sections: [synonymManagementSection],
    requiredPermission: ConflictsAction.ViewAndUpdateSynonymMaps,
    dataToLoad: ["SYNONYM_MAP", "SYNONYMS_STATUS"]
} as const);
